import { Grid } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import GddControls from "../../components/gdd-controls/GddControls";
import GddDialog from "../../components/gdd-dialog/GddDialog";
import GddDialogTitle from "../../components/gdd-dialog/GddDialogTitle";
import GddPlainDataTable from "../../components/gdd-table/GddPlainDataTable";
import TableTitle from "../../components/gdd-table/TableTitle";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import useAxios from "../../api/useAxios";
import papaparse from "papaparse";

function ParamImportDetails(props) {
    const { airlineId, open, setOpen, onAddParams, lookupData, setParentOpen } =
        props;

    const { ENDPOINTS, createApiEndPoint, axiosApiInstance } = useAxios();

    const [importType, setImportType] = useState(1);

    const [fileToImport, setFileToImport] = useState(null);

    const [selectedFlightData, setSelectedFlightData] = useState(null);

    const [flightData, setFlightData] = useState([]);

    const [tableData, setTableData] = useState([]);

    let paramsToImport = [];

    const iconType = <ViewHeadlineIcon iconSize="medium" />;
    const dlgTitle = (
        <GddDialogTitle title="Import Parameters" titleIcon={iconType} />
    );

    const tblIconType = <ViewHeadlineIcon iconSize="medium" />;
    const tableTitle = (
        <TableTitle title="Parameters" titleIcon={tblIconType} />
    );

    const inputRef = useRef();

    const cols = [
        {
            title: "Name",
            field: "name",
            filterPlaceholder: "Filter by Name",
        },
        {
            title: "Match?",
            field: "match",
            // type: "boolean",
            filterPlaceholder: "Filter by Match",
        },
    ];

    useEffect(() => {
        if (fileToImport && fileToImport !== "") {
            let dataToLookup = lookupData();

            papaparse.parse(fileToImport, {
                complete: function (results) {
                    let flattenedDataSet = new Set(); // Eliminates duplicates
                    results.data.forEach((item) => {
                        item[0] !== "" && flattenedDataSet.add(item[0]);
                    });

                    let data = [...flattenedDataSet].map((item) => {
                        return {
                            name: item,
                            match: dataToLookup.includes(item)
                                ? "true"
                                : "false",
                        };
                    });
                    setTableData(data);
                },
                error: function (errors) {
                    console.log(errors);
                },
            });
        } else {
            setTableData([]);
        }
    }, [fileToImport]);

    useEffect(() => {
        if (importType === 2 && airlineId > 0) {
            createApiEndPoint(ENDPOINTS.FLIGHTDATA)
                .fetchAll(`getKeyValuePairsByAirline?airlineId=${airlineId}`)
                .then((res) => {
                    const flightDataToList = res.data;
                    if (flightDataToList) {
                        let flightDataToDisplay = flightDataToList
                            .sort((a, b) => (a.name > b.name ? 1 : -1))
                            .map((item) => ({
                                id: item.id,
                                title: item.name,
                            }));
                        setFlightData(flightDataToDisplay);
                    } else {
                        setFlightData([]);
                    }
                })
                .catch((err) => console.log(err));
        } else {
            setFlightData([]);
        }
    }, [importType]);

    useEffect(() => {
        if (selectedFlightData > 0) {
            let dataToLookup = lookupData();

            createApiEndPoint(ENDPOINTS.FLIGHTDATA)
                .fetchAll(`?id=${selectedFlightData}`)
                .then((results) => {
                    let paramsList = results.data.mappingData.parameterList;
                    let data = paramsList.map((item) => {
                        return {
                            name: item.mnemonic,
                            match: dataToLookup.includes(item.mnemonic)
                                ? "true"
                                : "false",
                        };
                    });
                    setTableData(data);
                });
        } else {
            setTableData([]);
        }
    }, [selectedFlightData]);

    const cleanup = () => {
        setFileToImport(null);
        setFlightData(null);
        setSelectedFlightData(null);
        setTableData([]);

        if (importType === 1) inputRef.current.value = "";

        setImportType(1);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onAddParams(paramsToImport);
        cleanup();
        setParentOpen(true);
    };

    const getImportTypes = () => [
        {
            id: 1,
            title: "CSV",
        },
        {
            id: 2,
            title: "Existing Flight Data",
        },
    ];

    return (
        <div>
            <GddDialog
                title={dlgTitle}
                open={open}
                setOpen={setOpen}
                handleSubmit={handleSubmit}
                resetForm={() => cleanup()}
                onClosing={() => {
                    cleanup();
                    setParentOpen(true);
                }}
            >
                <Grid
                    container
                    rowSpacing={2}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                    <Grid item xs={12}>
                        <GddControls.GddSelect
                            fullWidth={true}
                            name="importType"
                            label="Import From"
                            required={false}
                            includeNone={false}
                            options={getImportTypes()}
                            value={importType}
                            onChange={(e) => {
                                setImportType(e.target.value);
                            }}
                            // error={errors.redactionTypeId}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        {importType === 1 && (
                            <GddControls.GddTextField
                                inputRef={inputRef}
                                fullWidth={true}
                                label="Select CSV file"
                                name="csvFile"
                                type="file"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                required={false}
                                onChange={(e) => {
                                    setFileToImport(e.target.files[0]);
                                }}
                            />
                        )}
                        {importType === 2 && (
                            <GddControls.GddSelect
                                fullWidth={true}
                                name="redaction"
                                label="Select Flight Data"
                                required={false}
                                includeNone={true}
                                options={flightData}
                                // value={importType}
                                onChange={(e) => {
                                    setSelectedFlightData(e.target.value);
                                }}
                            />
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <GddPlainDataTable
                            title={tableTitle}
                            cols={cols}
                            data={tableData}
                            showSelect={true}
                            showFilters={true}
                            onSelectionChange={(rows) => {
                                let params = rows.map((item) => item.name);
                                paramsToImport = [...params];
                            }}
                        />
                    </Grid>
                </Grid>
            </GddDialog>
        </div>
    );
}

ParamImportDetails.defaultProps = {
    lookupData: [],
};

export default ParamImportDetails;
