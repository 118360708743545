import React, { useEffect, useState, useRef } from "react";
import GddTable from "../../../components/gdd-table/GddTable";
import TableTitle from "../../../components/gdd-table/TableTitle";
import AircraftTypesIcon from "../../../icons/admin/AircraftTypesIcon";
import AircraftTypeDetails from "./AircraftTypeDetails";
import GddControls from "../../../components/gdd-controls/GddControls";
import { useGddForm } from "../../../components/gdd-form/UseGddForm";
import { useConfirm } from "material-ui-confirm";
import useAxios from "../../../api/useAxios";

const initialFieldValues = {
    id: 0,
    name: "",
    description: "",
    manufacturerId: "",
};

function AircraftTypesPage() {
    const { ENDPOINTS, createApiEndPoint, axiosApiInstance } = useAxios();

    const validate = (fieldValues = values) => {
        // Update error only for the given property.
        let temp = { ...errors };

        if ("name" in fieldValues)
            temp.name = fieldValues.name !== "" ? "" : "Name is required";

        if ("manufacturerId" in fieldValues)
            temp.manufacturerId =
                fieldValues.manufacturerId !== ""
                    ? ""
                    : "Airframer is required";

        setErrors({ ...temp });

        // Return the following only in case of whole form validation (not single form validation)
        if (fieldValues === values)
            return Object.values(temp).every((x) => x === "");
    };

    const confirm = useConfirm();

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm,
        editMode,
        setEditMode,
    } = useGddForm(initialFieldValues, validate, true);

    const iconType = <AircraftTypesIcon iconSize="medium" />;
    const tableTitle = (
        <TableTitle title="Aircraft Types" titleIcon={iconType} />
    );

    const [openPopup, setopenPopup] = useState(false);
    const [snackbarState, setSnackbarState] = useState({
        openSnackbar: false,
        snackbarMsg: "",
        snackbarSeverity: "success",
    });
    const { openSnackbar, snackbarMsg, snackbarSeverity } = snackbarState;
    const [fetchCount, setFetchCount] = useState(0);

    const tableRef = useRef();

    const [defaultFilters, setDefaultFilters] = useState({});

    const getDefaultFilter = (columnName) => {
        if (defaultFilters[columnName]) return defaultFilters[columnName];
        else return null;
    };

    const cols = [
        {
            title: "Id",
            field: "id",
            filterPlaceholder: "Id",
            hidden: true,
        },
        {
            title: "Name",
            field: "name",
            filterPlaceholder: "Filter by Name",
        },
        {
            title: "Airframer",
            field: "manufacturer",
            filterPlaceholder: "Filter by Airframer",
        },
        {
            title: "Description",
            field: "description",
            filterPlaceholder: "Filter by Description",
        },
    ];

    cols.forEach((item) => (item.defaultFilter = getDefaultFilter(item.field)));

    useEffect(() => {
        tableRef.current.onQueryChange();
    }, [fetchCount]);

    const addOrEdit = (acType, isEditMode) => {
        if (!isEditMode) {
            createAircraftType(acType);
        } else {
            updateAircraftType(acType);
        }
    };

    const createAircraftType = (acType) => {
        createApiEndPoint(ENDPOINTS.AIRCRAFTTYPE)
            .create(acType)
            .then((res) => {
                resetForm();
                setopenPopup(false);
                //setData([...data, res.data]);
                setFetchCount(fetchCount + 1);
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: "Successfully created Aircraft Type",
                    snackbarSeverity: "success",
                });
            })
            .catch((error) => {
                let msg = "Failed to create Aircraft Type: ";
                if (error.response && error.response.data) {
                    msg = error.response.data.message;
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }

                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const updateAircraftType = (acType) => {
        createApiEndPoint(ENDPOINTS.AIRCRAFTTYPE)
            .update(acType.id, acType)
            .then((res) => {
                resetForm();
                setopenPopup(false);
                // let updatedData = data.map(
                //     (obj) => [user].find((o) => o.id === obj.id) || obj
                // );
                // setData(updatedData);
                setFetchCount(fetchCount + 1);
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: "Successfully updated Aircraft Type",
                    snackbarSeverity: "success",
                });
            })
            .catch((error) => {
                let msg = "Failed to update Aircraft Type: ";
                if (error.response && error.response.data) {
                    msg = error.response.data.message;
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const deleteAircraftType = (acType) => {
        createApiEndPoint(ENDPOINTS.AIRCRAFTTYPE)
            .delete(acType.id)
            .then((res) => {
                // let updatedData = data.filter((obj) => obj.id !== airline.id);
                // setData(updatedData);
                setFetchCount(fetchCount + 1);
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: "Successfully deleted Aircraft Type",
                    snackbarSeverity: "success",
                });
            })
            .catch((error) => {
                let msg = "Failed to delete Aircraft Type: ";
                if (error.response && error.response.data) {
                    msg = error.response.data.message;
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }

                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };
    const addClicked = () => {
        resetForm();
        setEditMode(false);
        setopenPopup(true);
    };

    const editClicked = (selectedAcType) => {
        resetForm();
        createApiEndPoint(ENDPOINTS.AIRCRAFTTYPE)
            .fetchById(selectedAcType.id)
            .then((res) => {
                const acType = res.data;
                setValues({
                    ...values,
                    id: selectedAcType.id,
                    name: acType.name,
                    description: acType.description,
                    manufacturerId: acType.manufacturerId,
                });
                setEditMode(true);
                setopenPopup(true);
            })
            .catch((error) => {
                let msg = "Failed to fetch Aircraft Type: ";
                if (error.response && error.response.data) {
                    msg = error.response.data.message;
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }

                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const deleteClicked = (acType) => {
        confirm({
            description: `Are you sure you want to delete the Aircraft Type '${acType.name}?'`,
        })
            .then(() => deleteAircraftType(acType))
            .catch(() => {});
    };

    return (
        <div>
            <AircraftTypeDetails
                open={openPopup}
                setOpen={setopenPopup}
                addOrEdit={addOrEdit}
                // values={values}
                // setValues={setValues}
                // setErrors={setErrors}
                // errors={errors}
                // handleInputChange={handleInputChange}
                // resetForm={resetForm}
                // validate={validate}
                {...{
                    values,
                    setValues,
                    errors,
                    setErrors,
                    handleInputChange,
                    resetForm,
                    validate,
                    editMode,
                }}
            />

            <GddTable
                tableRef={tableRef}
                title={tableTitle}
                cols={cols}
                fetchEndPoint={ENDPOINTS.AIRCRAFTTYPE}
                saveFilters={setDefaultFilters}
                baseUrlExt="getPage"
                pagedSearchString="pagedSearch"
                onAdd={addClicked}
                onEdit={editClicked}
                onDelete={deleteClicked}
                options={{ exportFileName: "AircraftTypes" }}
            ></GddTable>
            <GddControls.GddSnackbar
                open={openSnackbar}
                message={snackbarMsg}
                onClose={() =>
                    setSnackbarState({ ...snackbarState, openSnackbar: false })
                }
                severity={snackbarSeverity}
            />
        </div>
    );
}

export default AircraftTypesPage;
