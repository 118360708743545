import React from "react";

import LegendToggleIcon from "@mui/icons-material/LegendToggle";

function IOStatIcon({ iconColor, iconSize = "small", ...other }) {
    return (
        <LegendToggleIcon color={iconColor} fontSize={iconSize} {...other} />
    );
}

export default IOStatIcon;
