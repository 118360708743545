import React, { useContext, useState } from "react";
import { AuthContext } from "../../auth/AuthContext";
import axios from "axios";
import { useGddForm } from "../../components/gdd-form/UseGddForm";
import GddControls from "../../components/gdd-controls/GddControls";

import {
    Avatar,
    Button,
    Card,
    CardContent,  
    Grid,
    Link,
    TextField,
    Typography,
} from "@mui/material";

import logoImage from "../../images/logo.png";
import MailOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';

function Footer(props) {
    return (
        <Grid align="center" style={{ marginBottom: "-40px" }} {...props}>
            <img
                src={logoImage}
                alt="TDY logo"
                height="65px"
                style={{ marginTop: "40px", align: "center" }}
            />
            <Typography variant="body2" color="text.secondary" align="center">
                {"Copyright © "}
                <Link color="inherit" href="www.teledynecontrols.com">
                    Teledyne Controls
                </Link>{" "}
                {new Date().getFullYear()}
                {"."}
            </Typography>
        </Grid>
    );
}

const initialValues = {   
    email: "",
    
};


function ForgotPassword(props) {

    const { loggedInUser, setLoggedInUser } = useContext(AuthContext);

    const [snackbarState, setSnackbarState] = useState({
        openSnackbar: false,
        snackbarMsg: "",
        snackbarSeverity: "success",
    });
    const { openSnackbar, snackbarMsg, snackbarSeverity } = snackbarState;

    const validate = (fieldValues = values) => {
        // Update error only for the given property.
     
        let temp = { ...errors };        

        if ("email" in fieldValues)
        temp.email = fieldValues.email !== "" ? "" : "Email is required";

        if (fieldValues.email)
            temp.email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.email)
                ? ""
                : "Email is not valid.";      
        
        setErrors({ ...temp });

        // Return the following only in case of whole form validation (not single form validation)
        if (fieldValues === values)
            return Object.values(temp).every((x) => x === "");
    };

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm,
        editMode,
        setEditMode,
    } = useGddForm(initialValues, validate, true);    

    const confirmEmail = (data) =>	
    {  
        let url = window.env.REACT_APP_API_BASE_URL + "/api/account/forgotPassword";	      

        axios({
            method: 'post',
            url: url,
            data: data
          })
          .then((res) => {
            setSnackbarState({
                ...snackbarState,
                openSnackbar: true,
                snackbarMsg: "Successfully sent password reset email",
                snackbarSeverity: "success",
            });

            window.location = "/home";
        })
        .catch((error) => {
            let msg = "Failed to send password reset email: ";
            if (error.response && error.response.data) {
                msg = error.response.data.message;
            } else if (error.request) {
                msg = msg + error.request;
            } else {
                msg = msg + "Error: " + error.message;
            }
            setSnackbarState({
                ...snackbarState,
                openSnackbar: true,
                snackbarMsg: msg,
                snackbarSeverity: "error",
            });
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();       

        let dataToPost = {
            email :  values.email,
           
        }  
        
        if (validate()) {   
           confirmEmail(dataToPost);
           }     
    };   
    
    
    return (
        <>
            <Grid {...props}>
                <Card
                    elevation={20}
                    style={{
                        padding: 20,
                        // height: "75vh",
                        width: "320px",
                        // width: "25vw",
                        margin: "20px auto",
                    }}
                >
                    <CardContent>
                        <Grid align="center">
                            <Avatar style={{ backgroundColor: "#64b1b7" }}>
                                <MailOutlinedIcon />
                            </Avatar>
                            <h2>Enter recovery Email</h2>
                        </Grid>

                        <GddControls.GddTextField
                            fullWidth={true}
                            autoComplete="off"
                            variant="standard"
                            label="Email"
                            name="email"
                            placeholder="Enter Email"
                            required={true}                          
                            value={values.email}
                            error={errors.email}
                            onChange={handleInputChange}
                        />                      
                        <Button
                            style={{ margin: "8px 0" }}
                            variant="contained"
                            type="submit"
                            color="primary"
                            fullWidth
                            onClick={(e) => handleSubmit(e)}
                        >
                            Submit
                        </Button>                    
                    </CardContent>
                </Card>
            </Grid>

            <GddControls.GddSnackbar
                open={openSnackbar}
                message={snackbarMsg}
                onClose={() =>
                    setSnackbarState({ ...snackbarState, openSnackbar: false })
                }
                severity={snackbarSeverity}
            />
        </>
    );      

}


export default ForgotPassword;