import React, { useContext } from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";

import AdminIcon from "../../icons/admin/AdminIcon";
import AdminAccountIcon from "../../icons/admin/AdminAccountIcon";
import AirlinesIcon from "../../icons/admin/AirlinesIcon";
import UsersIcon from "../../icons/admin/UsersIcon";
import ManufacturerIcon from "../../icons/admin/ManufacturersIcon";
import AircraftTypesIcon from "../../icons/admin/AircraftTypesIcon";
import EndPointIcon from "../../icons/param-mapping/EndPointIcon";
import styled from "styled-components/";
import { Tooltip } from "@mui/material";
import AccountConfigIcon from "../../icons/logged-user/AccountConfigIcon";
import { AuthContext } from "../../auth/AuthContext";
import OAuthIcon from "../../icons/security/OAuthIcon";

const ColorLink = styled(Link)``;

function AdminNav({ iconColor, routeTo, showToolTip }) {
    const [open, setOpen] = React.useState(true);

    const handleClick = () => {
        setOpen(!open);
    };

    const { loggedInUser } = useContext(AuthContext);

    const isAdmin = loggedInUser.role.toLowerCase() === "admin";

    return (
        <div>
            <List
                sx={{
                    width: "100%",
                    maxWidth: 360,
                    bgcolor: "background.paper",
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
            >
                <Tooltip title={showToolTip ? "Admin" : ""} arrow={true}>
                    <ListItemButton onClick={handleClick}>
                        <ListItemIcon>
                            <AdminIcon iconColor={iconColor} />
                        </ListItemIcon>
                        <ListItemText primary="Admin" />
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                </Tooltip>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {/* <ColorLink
                            style={{ textDecoration: "none", color: "inherit" }}
                            to="/accounts"
                        >
                            <Tooltip
                                title={showToolTip ? "Admin Account" : ""}
                                arrow={true}
                            >
                                <ListItemButton sx={{ pl: 4 }}>
                                    <ListItemIcon>
                                        <AdminAccountIcon
                                            iconColor={iconColor}
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary="Admin Account" />
                                </ListItemButton>
                            </Tooltip>
                        </ColorLink>

                        <ColorLink
                            style={{ textDecoration: "none", color: "inherit" }}
                            to="/accountconfig"
                        >
                            <Tooltip
                                title={showToolTip ? "Accounts Management" : ""}
                                arrow={true}
                            >
                                <ListItemButton sx={{ pl: 4 }}>
                                    <ListItemIcon>
                                        <AccountConfigIcon
                                            iconColor={iconColor}
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary="Accounts Management" />
                                </ListItemButton>
                            </Tooltip>
                        </ColorLink> */}
                        <ColorLink
                            style={{ textDecoration: "none", color: "inherit" }}
                            to="/airlines"
                        >
                            <Tooltip
                                title={showToolTip ? "Airlines" : ""}
                                arrow={true}
                            >
                                <ListItemButton sx={{ pl: 4 }}>
                                    <ListItemIcon>
                                        <AirlinesIcon iconColor={iconColor} />
                                    </ListItemIcon>
                                    <ListItemText primary="Airlines" />
                                </ListItemButton>
                            </Tooltip>
                        </ColorLink>

                        {isAdmin && (
                            <ColorLink
                                style={{
                                    textDecoration: "none",
                                    color: "inherit",
                                }}
                                to="/users"
                            >
                                <Tooltip
                                    title={showToolTip ? "Users" : ""}
                                    arrow={true}
                                >
                                    <ListItemButton sx={{ pl: 4 }}>
                                        <ListItemIcon>
                                            <UsersIcon iconColor={iconColor} />
                                        </ListItemIcon>
                                        <ListItemText primary="Users" />
                                    </ListItemButton>
                                </Tooltip>
                            </ColorLink>
                        )}

                        {isAdmin && (
                            <ColorLink
                                style={{
                                    textDecoration: "none",
                                    color: "inherit",
                                }}
                                to="/airframers"
                            >
                                <Tooltip
                                    title={showToolTip ? "Airframers" : ""}
                                    arrow={true}
                                >
                                    <ListItemButton sx={{ pl: 4 }}>
                                        <ListItemIcon>
                                            <ManufacturerIcon
                                                iconColor={iconColor}
                                            />
                                        </ListItemIcon>
                                        <ListItemText primary="Airframers" />
                                    </ListItemButton>
                                </Tooltip>
                            </ColorLink>
                        )}

                        {isAdmin && (
                            <ColorLink
                                style={{
                                    textDecoration: "none",
                                    color: "inherit",
                                }}
                                to="/aircrafttypes"
                            >
                                <Tooltip
                                    title={showToolTip ? "Aircraft Types" : ""}
                                    arrow={true}
                                >
                                    <ListItemButton sx={{ pl: 4 }}>
                                        <ListItemIcon>
                                            <AircraftTypesIcon
                                                iconColor={iconColor}
                                            />
                                        </ListItemIcon>
                                        <ListItemText primary="Aircraft Types" />
                                    </ListItemButton>
                                </Tooltip>
                            </ColorLink>
                        )}

                        {
                            //isAdmin &&
                            <ColorLink
                                style={{
                                    textDecoration: "none",
                                    color: "inherit",
                                }}
                                to="/endpointsconfig"
                            >
                                <Tooltip
                                    title={
                                        showToolTip ? "Endpoint Configs" : ""
                                    }
                                    arrow={true}
                                >
                                    <ListItemButton sx={{ pl: 4 }}>
                                        <ListItemIcon>
                                            <EndPointIcon
                                                iconColor={iconColor}
                                            />
                                        </ListItemIcon>
                                        <ListItemText primary="Endpoint Configs" />
                                    </ListItemButton>
                                </Tooltip>
                            </ColorLink>
                        }
                        <ColorLink
                            style={{
                                textDecoration: "none",
                                color: "inherit",
                            }}
                            to="/oAuth"
                        >
                            <Tooltip
                                title={showToolTip ? "OAuth" : ""}
                                arrow={true}
                            >
                                <ListItemButton sx={{ pl: 4 }}>
                                    <ListItemIcon>
                                        <OAuthIcon iconColor={iconColor} />
                                    </ListItemIcon>
                                    <ListItemText primary="OAuth" />
                                </ListItemButton>
                            </Tooltip>
                        </ColorLink>
                    </List>
                </Collapse>
            </List>
        </div>
    );
}

export default AdminNav;
