import React from "react";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

function GddWarningIcon({ iconColor, iconSize = "small", ...other }) {
    return (
        <WarningAmberIcon color={iconColor} fontSize={iconSize} {...other} />
    );
}

export default GddWarningIcon;
