import React from "react";

import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";

function DataFramesIcon({ iconColor, iconSize = "small", ...other }) {
    return (
        <AutoAwesomeMotionIcon
            color={iconColor}
            fontSize={iconSize}
            {...other}
        />
    );
}

export default DataFramesIcon;
