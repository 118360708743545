import { Autocomplete, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

function GddSingleSelect(props) {
    const {
        name,
        label,
        items,
        value,
        isRequired,
        error,
        placeholder,
        onChange,
        noOptionsText,
        autoCRef,
        ...rest
    } = props;

    const handleRenderOption = (props, option) => {
        <Box component="li" {...props}>
            {option.label}
            {/* {option.subLabel} */}
        </Box>;
    };

    return (
        <Autocomplete
            ref={autoCRef}
            size="small"
            fullWidth
            options={items}
            value={value}
            // getOptionLabel={getOptionLabel}
            noOptionsText={noOptionsText}
            onChange={onChange}
            renderInput={(params) => (
                <TextField
                    name={name}
                    {...(error && { error: true, helperText: error })}
                    placeholder={placeholder}
                    required={isRequired}
                    {...params}
                    label={label}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                />
            )}
        />
    );
}

GddSingleSelect.defaultProps = {
    items: [],
    value: "",
    getOptionLabel: (value) => value,
};

export default GddSingleSelect;
