import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import useAxios from "../../api/useAxios";
import GddControls from "../../components/gdd-controls/GddControls";
import GddDialog from "../../components/gdd-dialog/GddDialog";
import GddDialogTitle from "../../components/gdd-dialog/GddDialogTitle";
import RecorderConfigsIcon from "../../icons/recorderconfigs/RecorderConfigsIcon";

function RecorderConfigDetails(props) {
    const {
        open,
        setOpen,
        addOrEdit,
        values,
        setValues,
        setErrors,
        errors,
        handleInputChange,
        resetForm,
        validate,
        editMode,
        endPointParamsList,
        setEndPointParamsList,
    } = props;

    const iconType = <RecorderConfigsIcon iconSize="medium" />;
    const dlgTitle = (
        <GddDialogTitle
            title={
                editMode ? "Edit Recorder Config" : "Create New Recorder Config"
            }
            titleIcon={iconType}
        />
    );
    
    const { ENDPOINTS, createApiEndPoint, axiosApiInstance } = useAxios();
    const [recorderTypes, setRecorderTypes] = useState([]);
    const [recordingFormats, setRecordingFormats] = useState([]);
    const [airlines, setAirlines] = useState([]);
    const [blockModes, setBlockModes] = useState([]);
    const [isL3FA1000RecTypeSelected, setIsL3FA1000RecTypeSelected] =
        useState(false);

    const [isTdyNoTagsSelected, setIsTdyNoTagsSelected] = useState(false);

    const [showMiniFrames, setShowMiniFrames] = useState(false);

    const miniFrames = [
        { id: 4, title: "4" },
        { id: 6, title: "6" },
        { id: 8, title: "8" },
    ];
    const subFrameSizes = [
        { id: 0, title: "Auto Detect" },
        { id: 64, title: "64" },
        { id: 128, title: "128" },
        { id: 256, title: "256" },
        { id: 512, title: "512" },
        { id: 1024, title: "1024" },
        { id: 2048, title: "2048" },
        { id: 4096, title: "4096" },
    ];

    const dataPackingOptions = [
        { id: "autodetect", title: "Auto Detect" },
        { id: "noPacking", title: "No_Packing" },
        { id: "packed", title: "Packed" },
        { id: "unpacked", title: "UnPacked" },
    ];
    
    useEffect(() => {
        createApiEndPoint(ENDPOINTS.AIRLINE)
            .fetchAll("getKeyValuePairs")
            .then((res) => {
                const airlineData = res.data;
                let airlineOptions = airlineData.sort((a, b) => (a.name > b.name) ? 1 : -1).map((item) => ({
                    id: item.id,
                    title: item.name,
                }));
                setAirlines(airlineOptions);
            })
            .catch((err) => console.log(err));

        createApiEndPoint(ENDPOINTS.RECORDERTYPE)
            .fetchAll("getSelectListDto")
            .then((res) => {
                const recorderTypeData = res.data;
                let recorderTypeOptions = recorderTypeData.sort((a, b) => (a.name > b.name) ? 1 : -1).map((item) => ({
                    id: item.id,
                    title: item.name,
                }));
                setRecorderTypes(recorderTypeOptions);
            })
            .catch((err) => console.log(err));

        createApiEndPoint(ENDPOINTS.RECORDERFORMAT)
            .fetchAll("getKeyValuePairs")
            .then((res) => {
                const recordingFormatData = res.data;
                let recordingFormatOptions = recordingFormatData.sort((a, b) => (a.name > b.name) ? 1 : -1).map(
                    (item) => ({
                        id: item.id,
                        title: item.name,
                    })
                );
                setRecordingFormats(recordingFormatOptions);
            })
            .catch((err) => console.log(err));

        createApiEndPoint(ENDPOINTS.BLOCKMODE)
            .fetchAll("getKeyValuePairs")
            .then((res) => {
                const blockModeData = res.data;
                let blockModeOptions = blockModeData.sort((a, b) => (a.name > b.name) ? 1 : -1).map((item) => ({
                    id: item.id,
                    title: item.name,
                }));

                setBlockModes(blockModeOptions);
            })
            .catch((err) => console.log(err));
    }, []);
    
    useEffect(()=>{setShowMiniFrames(values.isMfs)},[values.isMfs]);
    useEffect(()=>{setIsL3FA1000RecTypeSelected(values.isL3_FA1000)},[values.isL3_FA1000]);
    useEffect(()=>{setIsTdyNoTagsSelected(values.isTdyNoTags)},[values.isTdyNoTags])
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            addOrEdit(values, editMode);
        }
    };
    const handleRecorderTypeChange = (e) => {
        let rectype = recorderTypes.find((item) => item.id === e.target.value);
        if (rectype && rectype.title === "L3_FA1000") {
            setIsL3FA1000RecTypeSelected(true);
            values["isL3_FA1000"] = true;
        } else {
            setIsL3FA1000RecTypeSelected(false);
            values["isL3_FA1000"] = false;
            values["blockModeId"] = "";
        }
        if (rectype && rectype.title === "TDY_NoTags") {
            setIsTdyNoTagsSelected(true);
            values["isTdyNoTags"] = true;
        } else {
            setIsTdyNoTagsSelected(false);
            values["isTdyNoTags"] = false;
            values["dataPacking"] = "";
        }

        handleInputChange(e);
    };

    const handleRecFormatChange = (e) => {
        let recFormatType = recordingFormats.find(
            (item) => item.id === e.target.value
        );
        if (recFormatType && recFormatType.title.includes("_No_Mfs")) {
            setShowMiniFrames(false);
            values["isMfs"] = false;
            values["miniFrames"] = "";
        } else {
            setShowMiniFrames(true);
            values["isMfs"] = true;
        }
        handleInputChange(e);
    };

    return (
        <div>
            <GddDialog
                title={dlgTitle}
                open={open}
                setOpen={setOpen}
                handleSubmit={handleSubmit}
                resetForm={resetForm}
            >
                <Grid
                    container
                    rowSpacing={2}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                    <Grid item xs={12}>
                        <GddControls.GddTextField
                            fullWidth={true}
                            name="name"
                            variant="outlined"
                            label="Name"
                            required={true}
                            value={values.name}
                            onChange={handleInputChange}
                            error={errors.name}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <GddControls.GddSelect
                            fullWidth={true}
                            name="airlineId"
                            label="Airline"
                            required={true}
                            options={airlines}
                            value={values.airlineId}
                            onChange={handleInputChange}
                            error={errors.airlineId}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <GddControls.GddSelect
                            fullWidth={true}
                            name="recorderTypeId"
                            label="Recorder Type"
                            required={true}
                            options={recorderTypes}
                            value={values.recorderTypeId}
                            onChange={(e) => handleRecorderTypeChange(e)}
                            error={errors.recorderTypeId}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <GddControls.GddSelect
                            fullWidth={true}
                            name="recorderFormatId"
                            label="Format"
                            required={true}
                            options={recordingFormats}
                            value={values.recorderFormatId}
                            onChange={(e) => handleRecFormatChange(e)}
                            error={errors.recorderFormatId}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <GddControls.GddSelect
                            fullWidth={true}
                            name="subFrameSize"
                            label="Subframe Size"
                            required={true}
                            options={subFrameSizes}
                            value={values.subFrameSize}
                            onChange={handleInputChange}
                            error={errors.subFrameSize}
                        />
                    </Grid>

                    {(isTdyNoTagsSelected || values.isTdyNoTags === true) && (
                        <Grid item xs={12}>
                            <GddControls.GddSelect
                                fullWidth={true}
                                name="dataPacking"
                                label="Data Packing"
                                required={true}
                                options={dataPackingOptions}
                                value={values.dataPacking}
                                onChange={handleInputChange}
                                error={errors.dataPacking}
                            />
                        </Grid>
                    )}

                    {(showMiniFrames || values.isMfs === true) && (
                        <Grid item xs={12}>
                            <GddControls.GddSelect
                                fullWidth={true}
                                name="miniFrames"
                                label="Mini Frame"
                                required={true}
                                options={miniFrames}
                                value={values.miniFrames}
                                onChange={handleInputChange}
                                error={errors.miniFrames}
                            />
                        </Grid>
                    )}
                    {(isL3FA1000RecTypeSelected ||
                        values.isL3_FA1000 === true) && (
                        <Grid item xs={12}>
                            <GddControls.GddSelect
                                fullWidth={true}
                                name="blockModeId"
                                label="Block Mode"
                                required={true}
                                options={blockModes}
                                value={values.blockModeId}
                                onChange={handleInputChange}
                                error={errors.blockModeId}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <GddControls.GddTextField
                            fullWidth={true}
                            name="customRegex"
                            variant="outlined"
                            label="Custom Regex"
                            value={values.customRegex}
                            onChange={handleInputChange}
                            error={errors.customRegex}
                        />
                    </Grid>
                </Grid>
            </GddDialog>
        </div>
    );
}

export default RecorderConfigDetails;
