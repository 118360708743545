import { Box, Grid, Paper, Tab, Tabs, Typography } from "@mui/material";
import React from "react";
import AirlinesIcon from "../../../icons/admin/AirlinesIcon";
import UsersIcon from "../../../icons/admin/UsersIcon";
import GddHomeIcon from "../../../icons/Home/GddHomeIcon";
import EndPointIcon from "../../../icons/param-mapping/EndPointIcon";
import EndPointsPage from "../../endpoints/EndPointsPage";
import AirlinesPage from "../airlines/AirlinesPage";
import UsersPage from "../users/UsersPage";

function AdminHomePage() {
    const [tabIndex, setTabIndex] = React.useState(0);

    const handleChange = (event, newTabIndex) => {
        setTabIndex(newTabIndex);
    };

    return (
        <>
            <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
                <Paper variant="outlined" elevation={10}>
                    <Grid display="flex">
                        <Grid
                            item
                            style={{
                                marginLeft: 25,
                                marginTop: 25,
                            }}
                        >
                            <GddHomeIcon />
                        </Grid>
                        <Grid
                            item
                            display="flex 1"
                            style={{
                                paddingTop: 4,
                                marginLeft: 20,
                                marginTop: 15,
                            }}
                        >
                            <Typography variant="h6" component="span">
                                Admin Home
                            </Typography>
                        </Grid>
                    </Grid>
                    <Tabs
                        value={tabIndex}
                        sx={{ mt: -7 }}
                        onChange={handleChange}
                        centered
                    >
                        <Tab
                            icon={<AirlinesIcon />}
                            iconPosition="start"
                            label="Airlines"
                        />
                        <Tab
                            icon={<UsersIcon />}
                            iconPosition="start"
                            label="Users"
                        />
                        <Tab
                            icon={<EndPointIcon />}
                            iconPosition="start"
                            label="Endpoints"
                        />
                    </Tabs>
                    {/* {tabIndex === 0 && <AirlinesPage />}
                    {tabIndex === 1 && <UsersPage />}
                    {tabIndex === 2 && <EndPointsPage />} */}
                </Paper>
            </Box>
            <Paper variant="outlined" elevation={10}>
                {tabIndex === 0 && <AirlinesPage />}
                {tabIndex === 1 && <UsersPage />}
                {tabIndex === 2 && <EndPointsPage />}
            </Paper>
        </>
    );
}

export default AdminHomePage;
