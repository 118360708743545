import React from "react";
import MaterialTable from "@material-table/core";

import { forwardRef } from "react";

import AddBox from "@mui/icons-material/AddBox";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import Check from "@mui/icons-material/Check";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Edit from "@mui/icons-material/Edit";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Remove from "@mui/icons-material/Remove";
import SaveAlt from "@mui/icons-material/SaveAlt";
import ViewColumn from "@mui/icons-material/ViewColumn";
import ReplayIcon from "@mui/icons-material/Replay";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { Paper } from "@mui/material";

const tableIcons = {
    Add: forwardRef((props, ref) => (
        <AddBox {...props} fontSize="small" ref={ref} />
    )),
    Check: forwardRef((props, ref) => (
        <Check {...props} fontSize="small" ref={ref} />
    )),
    Clear: forwardRef((props, ref) => (
        <Clear {...props} fontSize="small" ref={ref} />
    )),
    Delete: forwardRef((props, ref) => (
        <DeleteOutline {...props} fontSize="small" ref={ref} />
    )),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} fontSize="small" ref={ref} />
    )),
    Edit: forwardRef((props, ref) => (
        <EditOutlinedIcon {...props} fontSize="small" ref={ref} />
    )),
    Export: forwardRef((props, ref) => (
        <SaveAlt {...props} fontSize="small" ref={ref} />
    )),
    Filter: forwardRef((props, ref) => (
        <FilterList {...props} fontSize="small" ref={ref} />
    )),
    FirstPage: forwardRef((props, ref) => (
        <FirstPage {...props} fontSize="small" ref={ref} />
    )),
    LastPage: forwardRef((props, ref) => (
        <LastPage {...props} fontSize="small" ref={ref} />
    )),
    NextPage: forwardRef((props, ref) => (
        <ChevronRight {...props} fontSize="small" ref={ref} />
    )),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} fontSize="small" ref={ref} />
    )),
    SortArrow: forwardRef((props, ref) => (
        <ArrowDownward {...props} fontSize="small" ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
        <Remove {...props} fontSize="small" ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => (
        <ViewColumn {...props} fontSize="small" ref={ref} />
    )),
    EmailOutlinedIcon: forwardRef((props, ref) => (
        <EmailOutlinedIcon {...props} fontSize="small" ref={ref} />
    )),
};

function GddPlainDataTable({
    title,
    cols,
    data,
    onAdd,
    onEdit,
    onDelete,
    onDeleteAll,
    onRetry,
    onSelectionChange,
    getBaseUrlExt,
    showDeleteAll = false,
    showRetry = false,
    rowDataOverride = null,
    onRowDoubleClick = null,
    showAdd = false,
    showEdit = false,
    showDelete = false,
    showColumns = false,
    showGrouping = false,
    showFilters = false,
    showExport = false,
    showSelect = false,
    tableRef = null,
    ...other
}) {
    const getActions = () => {
        let actions = [];

        if (showAdd) {
            actions.push({
                icon: () => <AddBox sx={{ mt: "2px" }} />,
                tooltip: "Add",
                isFreeAction: true,
                onClick: (event) => {
                    onAdd(event);
                },
            });
        }

        if (showEdit) {
            actions.push({
                icon: () => <EditOutlinedIcon fontSize="inherit" />,
                tooltip: "Edit",
                onClick: (event, rowData) => {
                    onEdit(rowData);
                },
            });
        }

        if (showDelete) {
            actions.push({
                icon: () => <DeleteOutline fontSize="inherit" />,
                tooltip: "Delete",
                onClick: (event, rowData) => {
                    onDelete(rowData);
                },
            });
        }

        if (showDeleteAll) {
            actions.push({
                icon: () => <Clear fontSize="small" />,
                tooltip: "Delete All",
                isFreeAction: true,
                onClick: () => {
                    onDeleteAll();
                },
            });
        }

        if (showRetry) {
            actions.push({
                icon: () => <ReplayIcon fontSize="inherit" />,

                tooltip: "Retry",
                isFreeAction: false,
                onClick: (event, data) => {
                    onDeleteAll(data);
                },
            });
        }

        if (rowDataOverride) {
            actions.push((rowData) => {
                return rowDataOverride(rowData);
            });
        }

        return actions;
    };

    const getOptions = () => {
        let options = {
            columnsButton: showColumns,
            filtering: showFilters,
            search: false,
            grouping: showGrouping,
            exportButton: showExport,
            selection: showSelect,
            addRowPosition: "first",
            actionsColumnIndex: -1,
            padding: "dense",
            debounceInterval: 700,
            rowStyle: (data, index) =>
                index % 2 === 0 ? { background: "#f5f5f5" } : null,
            headerStyle: {
                // backgroundColor: "green",
                fontStyle: "italic",
            },
        };
        return options;
    };

    return (
        <Paper
            variant="elevation"
            elevation={10}
            sx={{
                p: 0,
                display: "flex",
                flexDirection: "column",
            }}
        >
            <MaterialTable
                style={{ fontSize: 14 }}
                icons={tableIcons}
                tableRef={tableRef}
                title={title}
                data={data}
                columns={cols}
                actions={getActions()}
                options={getOptions()}
                onSelectionChange={(rows) =>
                    onSelectionChange && onSelectionChange(rows)
                }
            ></MaterialTable>
        </Paper>
    );
}

export default GddPlainDataTable;
