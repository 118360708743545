import React from "react";
import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";

const ITEM_HEIGHT = 36;
const MOBILE_ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MENU_ITEMS = 5; // change this number to see the effect

function GddSelect(props) {
    const {
        name,
        label,
        value,
        onChange,
        error = null,
        options,
        optionId = true,
        includeNone = true,
        ...other
    } = props;

    return (
        <FormControl
            required
            variant="outlined"
            size="small"
            {...(error && { error: true })}
            {...other}
        >
            <InputLabel id="demo-simple-select-required-label">
                {label}
            </InputLabel>
            <Select
                labelId="demo-simple-select-required-label"
                id="demo-simple-select-required"
                name={name}
                value={value}
                label={label}
                onChange={onChange}
                MenuProps={{
                    PaperProps: {
                        sx: {
                            maxHeight: {
                                xs:
                                    MOBILE_ITEM_HEIGHT * MENU_ITEMS +
                                    ITEM_PADDING_TOP,
                                sm: ITEM_HEIGHT * MENU_ITEMS + ITEM_PADDING_TOP,
                            },
                        },
                    },
                }}
            >
                {includeNone && (
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                )}
                {options &&
                    options.map((item) => (
                        <MenuItem
                            key={item.id}
                            value={optionId ? item.id : item.title}
                        >
                            {item.title}
                        </MenuItem>
                    ))}
            </Select>
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    );
}

export default GddSelect;
