import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import GddControls from "../../../components/gdd-controls/GddControls";
import GddDialog from "../../../components/gdd-dialog/GddDialog";
import GddDialogTitle from "../../../components/gdd-dialog/GddDialogTitle";
import UsersIcon from "../../../icons/admin/UsersIcon";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import useAxios from "../../../api/useAxios";

function UserDetails(props) {
    const {
        open,
        setOpen,
        addOrEdit,
        values,
        setValues,
        setErrors,
        errors,
        handleInputChange,
        resetForm,
        validate,
        editMode,
    } = props;

    const { ENDPOINTS, createApiEndPoint, axiosApiInstance } = useAxios();

    const iconType = editMode ? (
        <UsersIcon iconSize="medium" />
    ) : (
        <PersonAddIcon iconSize="medium" />
    );
    const dlgTitle = (
        <GddDialogTitle
            title={editMode ? "Edit User" : "Create New User"}
            titleIcon={iconType}
        />
    );

    const [roles, setRoles] = useState([{id: 1, title:"Admin"}, {id: 2, title: "Manager"}]);
    const [airlines, setAirlines] = useState([]);

    useEffect(() => {
        createApiEndPoint(ENDPOINTS.ROLES)
            .fetchAll()
            .then((res) => {
                const rolesToDisplay = res.data;
                let roleOptions = rolesToDisplay.sort((a, b) => (a.name > b.name) ? 1 : -1).map((item) => ({
                    id: item.id,
                    title: item.name,
                }));
                setRoles(roleOptions);
            })
            .catch((err) => console.log(err));

        createApiEndPoint(ENDPOINTS.AIRLINE)
            .fetchAll("getPage?pagedSearch.page=1&pagedSearch.pageSize=1000")
            .then((res) => {
                const airlinesToDisplay = res.data.items;
                if (airlinesToDisplay) {
                    let airlineOptions = airlinesToDisplay.sort((a, b) => (a.name > b.name) ? 1 : -1).map((item) => ({
                        id: item.id,
                        label: item.name,
                        value: item.mane,
                        description: item.name,
                    }));
                    setAirlines(airlineOptions);
                } else {
                    setAirlines([]);
                }
            })
            .catch((err) => console.log(err));
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            let userToSubmit = {
                ...values,
                airlines: values.airlines.map((item) => ({
                    id: item.id,
                    name: item.label,
                })),
            };
            addOrEdit(userToSubmit, editMode);
        }
    };

    return (
        <div>
            <GddDialog
                title={dlgTitle}
                open={open}
                setOpen={setOpen}
                handleSubmit={handleSubmit}
                resetForm={resetForm}
            >
                <Grid
                    container
                    rowSpacing={2}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                    <Grid item xs={12}>
                        <GddControls.GddTextField
                            fullWidth={true}
                            name="userName"
                            variant="outlined"
                            label="User Name"
                            required={true}
                            value={values.userName}
                            onChange={handleInputChange}
                            error={errors.userName}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <GddControls.GddTextField
                            fullWidth={true}
                            name="fullName"
                            label="Full Name"
                            value={values.fullName}
                            onChange={handleInputChange}
                            error={errors.fullName}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <GddControls.GddTextField
                            fullWidth={true}
                            name="description"
                            label="Description"
                            required={false}
                            value={values.description}
                            onChange={handleInputChange}
                            error={errors.description}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <GddControls.GddTextField
                            fullWidth={true}
                            name="email"
                            label="Email"
                            required={true}
                            value={values.email}
                            onChange={handleInputChange}
                            error={errors.email}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <GddControls.GddSelect
                            fullWidth={true}
                            name="roleId"
                            label="Role"                           
                            required={true}
                            options={roles}
                            value={values.roleId}
                            onChange={handleInputChange}
                            error={errors.roleId}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <GddControls.GddMultiSelect
                            items={airlines}                           
                            name="airlines"
                            label="Airlines"
                            placeholder="Airlines"
                            selectAllLabel="Select all"
                            limitTags={3}
                            required
                            onChange={(v) => {
                                let evt = {
                                    target: {
                                        name: "airlines",
                                        value: [...v],
                                    },
                                };

                                handleInputChange(evt);
                            }}
                            values={values.airlines}
                            noOptionsText="No airlines to list"
                            includeImportOption={false}
                            error={errors.airlines}
                        />
                    </Grid>
                </Grid>
            </GddDialog>
        </div>
    );
}

export default UserDetails;
