import axios from "axios";
import React, { useState, createContext } from "react";
import jwt_decode from "jwt-decode";

export const AuthContext = createContext();

export const axiosApiInstance = axios.create();

export const AuthContextProvider = (props) => {
    const [loggedInUser, setLoggedInUser] = useState(null);

    const [logoutFlag, setLogoutFlag] = useState(false);

    const login = (userName, password) => {
        const tokenUrl = window.env.REACT_APP_API_BASE_URL + "/oauth/token";
        const config = {
            headers: {
                Authorization:
                    "BASIC Q2xpZW50MTpFRUY0N0Q5QS1EQkE5LTREMDItQjdCMC0wNEY0Mjc5QTZEMjA=",
                "Content-Type": "application/x-www-form-urlencoded",
            },
        };
        const postData = new URLSearchParams();
        postData.append("username", userName);
        postData.append("password", password);
        postData.append("grant_type", "password");

        axios
            .post(tokenUrl, postData, config)
            .then((res) => {
                let token = { ...res.data };
                let decodedAccess = jwt_decode(token.access_token);
                document.cookie = `refresh_token=${token.refresh_token}`;
                setLoggedInUser({
                    ...loggedInUser,
                    userName: decodedAccess.unique_name,
                    role: decodedAccess.role,
                    accessToken: token.access_token,
                    expiresIn: token.expires_in,
                    tokenType: token.token_type,
                });

                // window.location = "/home";
            })
            .catch((error) => console.log(error));
    };

    const refreshAccessToken = async () => {
        if (!logoutFlag) {
            const tokenUrl =
                window.env.REACT_APP_API_BASE_URL + "/oauth/token";
            const config = {
                headers: {
                    Authorization:
                        "BASIC Q2xpZW50MTpFRUY0N0Q5QS1EQkE5LTREMDItQjdCMC0wNEY0Mjc5QTZEMjA=",
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            };
            const postData = new URLSearchParams();
          /*  postData.append(
                "Refresh_token",
                document.cookie
                    .split(";")
                    .find((row) => row.startsWith("refresh_token="))
                    .split("=")[1]
            );*/
            postData.append(
                "Refresh_token",
                getCookie("refresh_token")
            );
            postData.append("grant_type", "refresh_token");

            await axios
                .post(tokenUrl, postData, config)
                .then((res) => {
                    let newToken = { ...res.data };
                    let decodedAccess = jwt_decode(newToken.access_token);
                    document.cookie = `refresh_token=${newToken.refresh_token}`;
                    setLoggedInUser({
                        ...loggedInUser,
                        userName: decodedAccess.unique_name,
                        role: decodedAccess.role,
                        accessToken: newToken.access_token,
                        expiresIn: newToken.expires_in,
                        tokenType: newToken.token_type,
                    });
                    //console.log(loggedInUser);
                    return Promise.resolve(newToken.access_token);
                })
                .catch((error) => {
                    console.log(error);
                    return Promise.reject("Refresh failed.");
                });
        } else {
            return Promise.reject("User has logged out.");
        }
    };

    const logout = () => {
        setLoggedInUser(null);
        setLogoutFlag(true);
        document.cookie = "refresh_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        return true;
    };

    return (
        <AuthContext.Provider
            value={{
                loggedInUser,
                setLoggedInUser,
                login,
                logout,
                refreshAccessToken,
            }}
        >
            {props.children}
        </AuthContext.Provider>
    );
};


export const getCookie = (name)=>{
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}
