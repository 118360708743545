import { Autocomplete, TextField, Checkbox, List, Chip } from "@mui/material";
import React, { useState } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function GddSearchList(props) {
    const { dataToList, selectedValues, setSelectedValuesToSave, placeHolder } =
        props;
    const [inputVals, setInputVals] = useState(selectedValues);

    return (
        <Autocomplete
            size="small"
            multiple
            limitTags={2}
            disableCloseOnSelect
            options={dataToList}
            value={inputVals}
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    {option.label}
                </li>
            )}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            // renderInput={(params) => (
            //     <TextField
            //         // inputProps={{ maxHeight: "50px" }}
            //         {...params}
            //         label="Parameters"
            //         placeholder="Parameters"
            //     />
            // )}

            renderInput={(params) => {
                const { InputProps, ...restParams } = params;
                const { startAdornment, ...restInputProps } = InputProps;
                return (
                    <TextField
                        placeholder={placeHolder}
                        {...restParams}
                        InputProps={{
                            ...restInputProps,
                            startAdornment: (
                                <div
                                    style={{
                                        maxHeight: 100,
                                        overflow: "auto",
                                    }}
                                >
                                    {startAdornment}
                                </div>
                            ),
                        }}
                    />
                );
            }}
            onChange={(e, v, r) => {
                setInputVals(v);
                setSelectedValuesToSave(v);
            }}
            // onInputChange={(e, v, r) => console.log(v)}
            // onFocus={() => setShowOwnersScroll(true)}
            // onBlur={() => setShowOwnersScroll(false)}
            // renderTags={renderTags}
        />
    );
}

export default GddSearchList;
