import React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import styled from "styled-components/";
import { Link } from "react-router-dom";

import StatIcon from "../../icons/stats/StatIcon";
import IOStatIcon from "../../icons/stats/IOStatIcon";
import FailureIcon from "../../icons/stats/FailureIcon";
import { Tooltip } from "@mui/material";

const ColorLink = styled(Link)``;

function StatsNav({ iconColor, showToolTip }) {
    const [open, setOpen] = React.useState(true);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <div>
            <List
                sx={{
                    width: "100%",
                    maxWidth: 360,
                    bgcolor: "background.paper",
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
            >
                <Tooltip title={showToolTip ? "Stats" : ""} arrow={true}>
                    <ListItemButton onClick={handleClick}>
                        <ListItemIcon>
                            <StatIcon iconColor={iconColor} />
                        </ListItemIcon>
                        <ListItemText primary="Stats" />
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                </Tooltip>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ColorLink
                            style={{
                                textDecoration: "none",
                                color: "inherit",
                            }}
                            to="/iostats"
                        >
                            <Tooltip
                                title={showToolTip ? "IO Stats" : ""}
                                arrow={true}
                            >
                                <ListItemButton sx={{ pl: 4 }}>
                                    <ListItemIcon>
                                        <IOStatIcon iconColor={iconColor} />
                                    </ListItemIcon>
                                    <ListItemText primary="IO Stats" />
                                </ListItemButton>
                            </Tooltip>
                        </ColorLink>
                        <ColorLink
                            style={{
                                textDecoration: "none",
                                color: "inherit",
                            }}
                            to="/failures"
                        >
                            <Tooltip
                                title={showToolTip ? "Failures" : ""}
                                arrow={true}
                            >
                                <ListItemButton sx={{ pl: 4 }}>
                                    <ListItemIcon>
                                        <FailureIcon iconColor={iconColor} />
                                    </ListItemIcon>
                                    <ListItemText primary="Failures" />
                                </ListItemButton>
                            </Tooltip>
                        </ColorLink>
                    </List>
                </Collapse>
            </List>
        </div>
    );
}

export default StatsNav;
