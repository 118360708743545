import React, { useState, useEffect, useRef } from "react";
import GddTable from "../../components/gdd-table/GddTable";
import TableTitle from "../../components/gdd-table/TableTitle";
import ReportIcon from "../../icons/param-mapping/ReportIcon";
import ReportDetails from "./ReportDetails";

import GddControls from "../../components/gdd-controls/GddControls";
import { useGddForm } from "../../components/gdd-form/UseGddForm";
import { useConfirm } from "material-ui-confirm";
import useAxios from "../../api/useAxios";

const initialFieldValues = {
    id: 0,
    name: "",
    description: "",
    dataFrameId: "",
    dataFrameName: "",
    airlineId: "",
    redactedReports: [],
};

function ReportsPage() {
    const { ENDPOINTS, createApiEndPoint, axiosApiInstance } = useAxios();

    const validate = (fieldValues = values) => {
        // Update error only for the given property.
        let temp = { ...errors };

        if ("name" in fieldValues)
            temp.name = fieldValues.name !== "" ? "" : "Name is required";

        if ("dataFrameId" in fieldValues)
            temp.dataFrameId =
                fieldValues.dataFrameId !== "" ? "" : "Data Frame is required";

        if ("airlineId" in fieldValues)
            temp.airlineId =
                fieldValues.airlineId !== "" ? "" : "Airline is required";

        if ("redactedReports" in fieldValues)
            temp.redactedReports =
                fieldValues.redactedReports.length > 0
                    ? ""
                    : "Reports selection is required";
        setErrors({ ...temp });

        // Return the following only in case of whole form validation (not single form validation)
        if (fieldValues === values)
            return Object.values(temp).every((x) => x === "");
    };
    const confirm = useConfirm();

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm,
        editMode,
        setEditMode,
    } = useGddForm(initialFieldValues, validate, true);

    const iconType = <ReportIcon iconSize="medium" />;
    const tableTitle = (
        <TableTitle title="Report Configurations" titleIcon={iconType} />
    );

    const [openPopup, setopenPopup] = useState(false);
    const [snackbarState, setSnackbarState] = useState({
        openSnackbar: false,
        snackbarMsg: "",
        snackbarSeverity: "success",
    });

    const { openSnackbar, snackbarMsg, snackbarSeverity } = snackbarState;
    const [fetchCount, setFetchCount] = useState(0);

    const tableRef = useRef();

    const [defaultFilters, setDefaultFilters] = useState({});

    const getDefaultFilter = (columnName) => {
        if (defaultFilters[columnName]) return defaultFilters[columnName];
        else return null;
    };

    const cols = [
        {
            title: "Id",
            field: "id",
            filterPlaceholder: "Filter by Id",
            hidden: true,
        },
        {
            title: "Name",
            field: "name",
            filterPlaceholder: "Filter by Name",
        },
        {
            title: "Description",
            field: "description",
            filterPlaceholder: "Filter by Description",
        },
        {
            title: "Data Frame",
            field: "dataFrame",
            filterPlaceholder: "Filter by Data Frame",
        },
    ];

    cols.forEach((item) => (item.defaultFilter = getDefaultFilter(item.field)));

    useEffect(() => {
        tableRef.current.onQueryChange();
    }, [fetchCount]);

    const addOrEdit = (reportConfigData, isEditMode) => {
        let dataToPost = {
            id: reportConfigData.id,
            name: reportConfigData.name,
            description: reportConfigData.description,
            reportList: reportConfigData.redactedReports.map((item) => ({
                id: item.id,
                name: item.label,
                pattern: item.value,
                description: item.description,
            })),
            dataFrameId: reportConfigData.dataFrameId,
            airlineId: reportConfigData.airlineId,
            includeOriginalMessageData: false,
        };

        if (!isEditMode) {
            createReport(dataToPost);
        } else {
            updateReport(dataToPost);
        }
    };

    const createReport = (reportData) => {
        createApiEndPoint(ENDPOINTS.REPORTS)
            .create(reportData)
            .then((res) => {
                resetForm();
                setopenPopup(false);
                //setData([...data, res.data]);
                setFetchCount(fetchCount + 1);
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: "Successfully created Report Configuration",
                    snackbarSeverity: "success",
                });
            })
            .catch((error) => {
                let msg = "Failed to create Report Configuration: ";
                if (error.response && error.response.data) {
                    msg = error.response.data.message;
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const updateReport = (reportData) => {
        createApiEndPoint(ENDPOINTS.REPORTS)
            .update(reportData.id, reportData)
            .then((res) => {
                resetForm();
                setopenPopup(false);
                setFetchCount(fetchCount + 1);
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: "Successfully updated Report Configuration",
                    snackbarSeverity: "success",
                });
            })
            .catch((error) => {
                let msg = "Failed to update Report Configuration: ";
                if (error.response && error.response.data) {
                    msg = error.response.data.message;
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }

                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const deleteReport = (reportData) => {
        createApiEndPoint(ENDPOINTS.REPORTS)
            .delete(reportData.id)
            .then((res) => {
                setFetchCount(fetchCount + 1);
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: "Successfully deleted Report Configuration",
                    snackbarSeverity: "success",
                });
            })
            .catch((error) => {
                let msg = "Failed to delete Report Configuration: ";
                if (error.response && error.response.data) {
                    msg = error.response.data.message;
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }

                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const addClicked = () => {
        resetForm();
        setEditMode(false);
        setopenPopup(true);
    };

    const editClicked = (selectedReport) => {
        resetForm();
        createApiEndPoint(ENDPOINTS.REPORTS)
            .fetchById(selectedReport.id)
            .then((res) => {
                const reportData = res.data;
                
                setValues({
                    ...values,

                    id: selectedReport.id,
                    name: reportData.name,
                    description: reportData.description,
                    airlineId: reportData.airlineId,
                    dataFrameId: reportData.dataFrameId,
                    includeOriginalMessageData: false,
                    redactedReports: reportData.reportList.map((item) => ({
                        id: item.id === 0 ? item.name : item.id,
                        label: item.name,
                        value: item.pattern,
                        description: item.name,
                    })),
                });
                setEditMode(true);
                setopenPopup(true);
            })
            .catch((error) => {
                let msg = "Failed to fetch User: ";
                if (error.response && error.response.data) {
                    msg = error.response.data.message;
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }

                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const deleteClicked = (reportData) => {
        confirm({
            description: `Are you sure you want to delete the Report '${reportData.name}?'`,
        })
            .then(() => deleteReport(reportData))
            .catch(() => {});
    };

    return (
        <div>
            <ReportDetails
                open={openPopup}
                setOpen={setopenPopup}
                addOrEdit={addOrEdit}
                isAdmin={true}
                {...{
                    values,
                    setValues,
                    errors,
                    setErrors,
                    handleInputChange,
                    resetForm,
                    validate,
                    editMode,
                }}
            />
            <GddTable
                tableRef={tableRef}
                title={tableTitle}
                cols={cols}
                fetchEndPoint={ENDPOINTS.REPORTS}
                saveFilters={setDefaultFilters}
                baseUrlExt="getPageNoRedaction"
                pagedSearchString="pagedSearch"
                options={{ exportFileName: "Reports" }}
                onAdd={addClicked}
                onEdit={editClicked}
                onDelete={deleteClicked}
            ></GddTable>
            <GddControls.GddSnackbar
                open={openSnackbar}
                message={snackbarMsg}
                onClose={() =>
                    setSnackbarState({ ...snackbarState, openSnackbar: false })
                }
                severity={snackbarSeverity}
            />
        </div>
    );
}

export default ReportsPage;
