import React, { useEffect, useState, useRef } from "react";
import GddTable from "../../components/gdd-table/GddTable";
import TableTitle from "../../components/gdd-table/TableTitle";
import FleetIcon from "../../icons/aircraft-info/FleetIcon";
import { useGddForm } from "../../components/gdd-form/UseGddForm";
import GddControls from "../../components/gdd-controls/GddControls";
import useAxios from "../../api/useAxios";
import { useConfirm } from "material-ui-confirm";
import FleetDetails from "./FleetDetails";
import { WindowRounded } from "@mui/icons-material";

const initialFieldValues = {
    id: 0,
    name: "",
    description: "",
    aircraftTypeId: "",
    airlineId: "",
    numberOfEngines: "",
};

function FleetsPage() {
    const { ENDPOINTS, createApiEndPoint, axiosApiInstance } = useAxios();

    const validate = (fieldValues = values) => {
        // Update error only for the given property.
        let temp = { ...errors };
       
        if ("name" in fieldValues)
            temp.name = fieldValues.name !== "" ? "" : "Name is required";

        if ("aircraftTypeId" in fieldValues)
            temp.aircraftTypeId =
                fieldValues.aircraftTypeId !== "" ? "" : "Aircraft Type is required";

        if ("numberOfEngines" in fieldValues)
            temp.numberOfEngines =
                fieldValues.numberOfEngines !== "" ? "" : "Engine Count is required";
        
        if("airlineId" in fieldValues)
              temp.airlineId = 
                fieldValues.airlineId  !== "" ? "" : "airline is required";     

        setErrors({ ...temp });
       
        // Return the following only in case of whole form validation (not single form validation)
        if (fieldValues === values)
            return Object.values(temp).every((x) => x === "");
    };

    const confirm = useConfirm();

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm,
        editMode,
        setEditMode,
    } = useGddForm(initialFieldValues, validate, true);

    const iconType = <FleetIcon iconSize="medium" />;
    const tableTitle = <TableTitle title="Fleets" titleIcon={iconType} />;

    const [openPopup, setopenPopup] = useState(false);

    const [snackbarState, setSnackbarState] = useState({
        openSnackbar: false,
        snackbarMsg: "",
        snackbarSeverity: "success",
    });

    const { openSnackbar, snackbarMsg, snackbarSeverity } = snackbarState;
    const [fetchCount, setFetchCount] = useState(0);

    const tableRef = useRef();

    const [defaultFilters, setDefaultFilters] = useState({});

    const getDefaultFilter = (columnName) => {
        if (defaultFilters[columnName]) return defaultFilters[columnName];
        else return null;
    };

    const cols = [
        {
            title: "Id",
            field: "id",
            filterPlaceholder: "Filter by Id",
            hidden: true,
        },
        {
            title: "Name",
            field: "name",
            filterPlaceholder: "Filter by Name",
        },
        {
            title: "Aircraft Type",
            field: "aircraftType",
            filterPlaceholder: "Filter by Aircraft Type",
        },
        {
            title: "Airline",
            field: "airline",
            filterPlaceholder: "Filter by Airline",
        },
        {
            title: "Engine Count",
            field: "engineCount",
            filterPlaceholder: "Filter by Engine Count",
            type: "numeric",
        },
    ];

    cols.forEach((item) => (item.defaultFilter = getDefaultFilter(item.field)));

    useEffect(() => {
        tableRef.current.onQueryChange();
    }, [fetchCount]);

    const addOrEdit = (fleet, isEditMode) => {
        if (!isEditMode) {
            createFleet(fleet);
        } else {
            updateFleet(fleet);
        }
    };

    const createFleet = (fleet) => {
        createApiEndPoint(ENDPOINTS.FLEET)
            .create(fleet)
            .then((res) => {
                resetForm();
                setopenPopup(false);
                //setData([...data, res.data]);
                setFetchCount(fetchCount + 1);
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: "Successfully created Fleet",
                    snackbarSeverity: "success",
                });
            })
            .catch((error) => {
                console.log(error.response);
                let msg = "Failed to create Fleet: ";
                if (error.response && error.response.data) {
                    if (error.response.data.exceptionMessage) {
                        msg = error.response.data.exceptionMessage;
                    } else {
                        msg = error.response.data.message;
                    }
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const updateFleet = (fleet) => {
        createApiEndPoint(ENDPOINTS.FLEET)
            .update(fleet.id, fleet)
            .then((res) => {
                resetForm();
                setopenPopup(false);
                // let updatedData = data.map(
                //     (obj) => [res.data].find((o) => o.id === obj.id) || obj
                // );
                // setData(updatedData);
                setFetchCount(fetchCount + 1);
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: "Successfully updated Fleet",
                    snackbarSeverity: "success",
                });
            })
            .catch((error) => {
                let msg = "Failed to update Fleet: ";
                if (error.response && error.response.data) {
                    msg = error.response.data.message;
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }

                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const deleteFleet = (fleet) => {
        createApiEndPoint(ENDPOINTS.FLEET)
            .delete(fleet.id)
            .then((res) => {
                // let updatedData = data.filter((obj) => obj.id !== airline.id);
                // setData(updatedData);
                setFetchCount(fetchCount + 1);
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: "Successfully deleted Fleet",
                    snackbarSeverity: "success",
                });
            })
            .catch((error) => {
                let msg = "Failed to delete Fleet: ";
                if (error.response && error.response.data) {
                    msg = error.response.data.message;
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }

                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const addClicked = () => {
        resetForm();
        setEditMode(false);
        setopenPopup(true);
    };

    const editClicked = (selectedFleet) => {
        resetForm();
        createApiEndPoint(ENDPOINTS.FLEET)
            .fetchById(selectedFleet.id)
            .then((res) => {
                const fleet = res.data;
                setValues({
                    ...values,
                    id: selectedFleet.id,
                    name: fleet.name,
                    description: fleet.description,
                    aircraftTypeId: fleet.aircraftTypeId,
                    airlineId: fleet.airlineId,
                    numberOfEngines: fleet.numberOfEngines,
                });
                setEditMode(true);
                setopenPopup(true);
            })
            .catch((error) => {
                let msg = "Failed to fetch Fleet: ";
                if (error.response && error.response.data) {
                    msg = error.response.data.message;
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }

                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const deleteClicked = (fleet) => {
        confirm({
            description: `Are you sure you want to delete the aircraft '${fleet.name}?'`,
        })
            .then(() => deleteFleet(fleet))
            .catch(() => {});
    };

    return (
        <div>
            <FleetDetails
                open={openPopup}
                setOpen={setopenPopup}
                addOrEdit={addOrEdit}
                {...{
                    values,
                    setValues,
                    errors,
                    setErrors,
                    handleInputChange,
                    resetForm,
                    validate,
                    editMode,
                }}
            />
            <GddTable
                tableRef={tableRef}
                title={tableTitle}
                cols={cols}
                fetchEndPoint={ENDPOINTS.FLEET}
                saveFilters={setDefaultFilters}
                baseUrlExt="getPage"
                pagedSearchString="pagedSearch"
                onAdd={addClicked}
                onEdit={editClicked}
                onDelete={deleteClicked}
            ></GddTable>

            <GddControls.GddSnackbar
                open={openSnackbar}
                message={snackbarMsg}
                onClose={() =>
                    setSnackbarState({ ...snackbarState, openSnackbar: false })
                }
                severity={snackbarSeverity}
            />
        </div>
    );
}

export default FleetsPage;
