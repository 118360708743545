import React, { useContext } from "react";
import { Route } from "react-router-dom";
import { AuthContext } from "../../auth/AuthContext";

export const ProtectedRoute = ({ component: Component, ...rest }) => {
    const { loggedInUser, refreshAccessToken} = useContext(AuthContext);

    return (
        <Route
            {...rest}
            render={(props) => {
                if (loggedInUser) {
                    return <Component {...props} />;
                }
                else{
                    refreshAccessToken()
                        .catch((error) => {
                        console.log(error);
                        props.history.push({
                            pathname: "/",
                            state: {
                                from: props.location
                            }
                        });
                    });
                }
            }}
        />
    );
};
