import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";

function GddLoadingButton(props) {
    const { text, size, color, disabled, variant, onClick, loading, ...other } =
        props;

    return (
        <LoadingButton
            sx={{ textTransform: "none", margin: "2px" }}
            variant={variant || "contained"}
            size={size || "small"}
            color={color || "primary"}
            disabled={disabled}
            onClick={onClick}
            loading={loading}
            {...other}
        >
            {text}
        </LoadingButton>
    );
}

export default GddLoadingButton;
