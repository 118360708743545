import React from "react";

import AirplaneTicketIcon from "@mui/icons-material/AirplaneTicket";

function FlightDataIcon({ iconColor, iconSize = "small", ...other }) {
    return (
        <AirplaneTicketIcon color={iconColor} fontSize={iconSize} {...other} />
    );
}

export default FlightDataIcon;
