import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import GddControls from "../../components/gdd-controls/GddControls";
import GddDialog from "../../components/gdd-dialog/GddDialog";
import GddDialogTitle from "../../components/gdd-dialog/GddDialogTitle";
import { useGddForm } from "../../components/gdd-form/UseGddForm";
import FleetIcon from "../../icons/aircraft-info/FleetIcon";
import useAxios from "../../api/useAxios";

function FleetDetails(props) {
    const {
        open,
        setOpen,
        addOrEdit,
        values,
        setValues,
        setErrors,
        errors,
        handleInputChange,
        resetForm,
        validate,
        editMode,
    } = props;

    const { ENDPOINTS, createApiEndPoint, axiosApiInstance } = useAxios();

    const iconType = <FleetIcon iconSize="medium" />;
    const dlgTitle = (
        <GddDialogTitle
            title={editMode ? "Edit Fleet" : "Create New Fleet"}
            titleIcon={iconType}
        />
    );

    const [airlines, setAirlines] = useState([]);
    const [aircraftTypes, setAircraftTypes] = useState([]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            addOrEdit(values, editMode);
        }
    };

    useEffect(() => {
        createApiEndPoint(ENDPOINTS.AIRLINE)
            .fetchAll("getPage?pagedSearch.page=1&pagedSearch.pageSize=1000")
            .then((res) => {
                const airlines = res.data.items;
                let airlineOptions = airlines
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((item) => ({
                        id: item.id,
                        title: item.name,
                    }));
                setAirlines(airlineOptions);
            })
            .catch((err) => console.log(err));

        createApiEndPoint(ENDPOINTS.AIRCRAFTTYPE)
            .fetchAll("getPage?pagedSearch.page=1&pagedSearch.pageSize=1000")
            .then((res) => {
                const aircraftTypes = res.data.items;
                let aircraftTypeOptions = aircraftTypes
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((item) => ({
                        id: item.id,
                        title: item.name,
                    }));
                setAircraftTypes(aircraftTypeOptions);
            })
            .catch((err) => console.log(err));
    }, []);

    return (
        <GddDialog
            title={dlgTitle}
            open={open}
            setOpen={setOpen}
            handleSubmit={handleSubmit}
            resetForm={resetForm}
        >
            <Grid
                container
                rowSpacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
                <Grid item xs={6}>
                    <GddControls.GddTextField
                        fullWidth={true}
                        name="name"
                        variant="outlined"
                        label="Name"
                        required={true}
                        value={values.name}
                        onChange={handleInputChange}
                        error={errors.name}
                    />
                </Grid>
                <Grid item xs={6}>
                    <GddControls.GddTextField
                        fullWidth={true}
                        name="description"
                        label="Description"
                        required={false}
                        value={values.description}
                        onChange={handleInputChange}
                        error={errors.description}
                    />
                </Grid>
                <Grid item xs={6}>
                    <GddControls.GddSelect
                        fullWidth={true}
                        name="aircraftTypeId"
                        label="Aircraft Type"
                        required={true}
                        options={aircraftTypes}
                        value={values.aircraftTypeId}
                        onChange={handleInputChange}
                        error={errors.aircraftTypeId}
                    />
                </Grid>
                <Grid item xs={6}>
                    <GddControls.GddSelect
                        fullWidth={true}
                        name="airlineId"
                        label="Airline"
                        required={true}
                        options={airlines}
                        value={values.airlineId}
                        onChange={handleInputChange}
                        error={errors.airlineId}
                    />
                </Grid>
                <Grid item xs={6}>
                    <GddControls.GddTextField
                        fullWidth={true}
                        name="numberOfEngines"
                        variant="outlined"
                        label="Number of Engines"
                        required={true}
                        value={values.numberOfEngines}
                        onChange={handleInputChange}
                        error={errors.numberOfEngines}
                    />
                </Grid>
            </Grid>
        </GddDialog>
    );
}

export default FleetDetails;
