import React from "react";

import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";

function AircraftIcon({ iconColor, iconSize = "small", ...other }) {
    return (
        <FlightTakeoffIcon color={iconColor} fontSize={iconSize} {...other} />
    );
}

export default AircraftIcon;
