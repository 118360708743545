import React from "react";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";

function ManufacturerIcon({ iconColor, iconSize = "small", ...other }) {
    return (
        <PrecisionManufacturingIcon
            color={iconColor}
            fontSize={iconSize}
            {...other}
        />
    );
}

export default ManufacturerIcon;
