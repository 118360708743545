import { Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import GddControls from "../../components/gdd-controls/GddControls";
import GddDialog from "../../components/gdd-dialog/GddDialog";
import GddDialogTitle from "../../components/gdd-dialog/GddDialogTitle";
import TableTitle from "../../components/gdd-table/TableTitle";
import DataFrameIcon from "../../icons/param-mapping/DataFramesIcon";
import { useConfirm } from "material-ui-confirm";
import useAxios from "../../api/useAxios";
import GddDataTableWithAutoEdit from "../../components/gdd-table/GddDataTableWithAutoEdit";
import GddWarningIcon from "../../icons/general/GddWarningIcon";
import RulesIcon from "../../icons/param-mapping/RulesIcon";

function DataFrameDetails(props) {
    const {
        open,
        setOpen,
        addOrEdit,
        values,
        setValues,
        setErrors,
        errors,
        handleInputChange,
        resetForm,
        validate,
        editMode,
        isAdmin,
        setB787Show,
        b787Show,
        b787RulesData,
        setB787RulesData,
    } = props;

    const { ENDPOINTS, createApiEndPoint, axiosApiInstance } = useAxios();

    const iconType = <DataFrameIcon iconSize="medium" />;

    const tableIconType = <RulesIcon iconSize="medium" />;
    const tableTitle = <TableTitle title="Rules" titleIcon={tableIconType} />;

    const confirm = useConfirm();

    const dlgTitle = (
        <GddDialogTitle
            title={editMode ? "Edit Data Frame" : "Create New Data Frame"}
            titleIcon={iconType}
        />
    );

    const cols = [
        {
            title: "Id",
            field: "id",
            editable: false,
        },

        {
            title: "Rule Name",
            field: "name",
            validate: (rowData) =>
                rowData.name === undefined || rowData.name === ""
                    ? { isValid: false, helperText: "Rule name is required" }
                    : true,
        },
        {
            title: "Rule RegEx",
            field: "pattern",
            validate: (rowData) =>
                rowData.pattern === undefined || rowData.pattern === ""
                    ? { isValid: false, helperText: "Pattern is required" }
                    : true,
        },
    ];

    //const [isB787Type, set787Type] = useState(false);
    const [snackbarState, setSnackbarState] = useState({
        openSnackbar: false,
        snackbarMsg: "",
        snackbarSeverity: "success",
    });
    const { openSnackbar, snackbarMsg, snackbarSeverity } = snackbarState;

    const [dataFrameTypes, setDataFrameTypes] = useState([]);
    const [airlines, setAirlines] = useState([]);

    const [uploadFile, setUploadFile] = useState();

    const fileInput = React.createRef();

    const [b787RuleName, setB787RuleName] = useState("");
    const [b787RuleRegEx, setB787RuleRegEx] = useState("");

    const On787TypeChange = (e) => {
        setB787Show(!b787Show);
    };

    const OnAddClick = (e) => {
        e.preventDefault();

        if (b787RuleName === "" || b787RuleRegEx === "") {
            alert("Both B787 rule name and regex are required");
            return;
        }

        if (b787RulesData.some((item) => item.name === b787RuleName)) {
            alert("A rule with the same name already exists");
            return;
        }

        if (b787RulesData.some((item) => item.pattern === b787RuleRegEx)) {
            alert("A RegEx with the same definition already exists");
            return;
        }

        setB787RulesData((b787RulesData) => [
            ...b787RulesData,
            { name: b787RuleName, pattern: b787RuleRegEx },
        ]);

        setB787RuleName("");
        setB787RuleRegEx("");
    };

    const getUniqueId = () => {
        if (
            b787RulesData === undefined ||
            b787RulesData === null ||
            b787RulesData.length === 0
        )
            return 1;
        else {
            let maxId = Math.max(...b787RulesData.map((rule) => rule.id));
            return maxId + 1;
        }
    };

    const validateRule = (rule) => {
        if (
            b787RulesData.some(
                (item) => item.name === rule.name && item.id !== rule.id
            )
        ) {
            setSnackbarState({
                ...snackbarState,
                openSnackbar: true,
                snackbarMsg: "A rule with the same name already exists",
                snackbarSeverity: "error",
            });
            return false;
        } else if (
            b787RulesData.some(
                (item) => item.pattern === rule.pattern && item.id !== rule.id
            )
        ) {
            setSnackbarState({
                ...snackbarState,
                openSnackbar: true,
                snackbarMsg: "A RegEx with the same definition already exists",
                snackbarSeverity: "error",
            });
            return false;
        } else {
            return true;
        }
    };

    const onAddRule = (newRule) => {
        const rowId = getUniqueId();
        let rule = { ...newRule, id: rowId };

        if (validateRule(rule)) {
            newRule.id = rowId;
            setB787RulesData([...b787RulesData, newRule]);
            return true;
        } else {
            return false;
        }
    };

    const onUpdateRule = (newRule, oldRule) => {
        if (!validateRule(newRule)) {
            return false;
        } else {
            const index = b787RulesData.findIndex(
                (item) => item.id === oldRule.id
            );
            if (index !== -1) {
                const dataUpdate = [...b787RulesData];
                dataUpdate[index] = newRule;
                setB787RulesData([...dataUpdate]);
            }
            return true;
        }
    };

    const onDeleteRule = (rule) => {
        const dataDelete = b787RulesData.filter((item) => item.id !== rule.id);
        setB787RulesData(dataDelete);
        return true;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        let formData = new FormData();
        formData.append("file", uploadFile);

        if (validate()) {
            addOrEdit(values, editMode, formData);
        }
    };

    useEffect(() => {
        createApiEndPoint(ENDPOINTS.DATAFRAMETYPES)
            .fetchAll("getKeyValuePairs")
            .then((res) => {
                const dtFrameTypes = res.data;
                let dtFrameTypeOptions = dtFrameTypes
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((item) => ({
                        id: item.id,
                        title: item.name,
                    }));
                setDataFrameTypes(dtFrameTypeOptions);
            })
            .catch((err) => console.log(err));

        if (isAdmin) {
            createApiEndPoint(ENDPOINTS.AIRLINE)
                .fetchAll(
                    "getPage?pagedSearch.page=1&pagedSearch.pageSize=1000"
                )
                .then((res) => {
                    const airlines = res.data.items;
                    let airlineOptions = airlines
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((item) => ({
                            id: item.id,
                            title: item.name,
                        }));
                    setAirlines(airlineOptions);
                })
                .catch((err) => console.log(err));
        }
    }, []);

    const deleteRule = (rule) => {
        setB787RulesData(
            b787RulesData.filter((item) => item.name !== rule.name)
        );
    };

    const deleteClicked = (rule) => {
        confirm({
            description: `Are you sure you want to delete the B787 Rule '${rule.name}'?`,
        })
            .then(() => deleteRule(rule))
            .catch(() => {});
    };

    const resetFormPlus = (e) => {
        resetForm();
        setB787RulesData([]);
    };

    return (
        <GddDialog
            title={dlgTitle}
            open={open}
            setOpen={setOpen}
            handleSubmit={handleSubmit}
            resetForm={resetFormPlus}
        >
            <Grid
                container
                rowSpacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
                <Grid item xs={6}>
                    <GddControls.GddTextField
                        fullWidth={true}
                        name="name"
                        variant="outlined"
                        label="Name"
                        required={true}
                        value={values.name}
                        onChange={handleInputChange}
                        error={errors.name}
                    />
                </Grid>
                {!editMode && (
                    <Grid item xs={6}>
                        <GddControls.GddCheckbox
                            fullWidth={true}
                            name="is787TypeChkBox"
                            label="B787 Report Type"
                            value={b787Show}
                            onChange={On787TypeChange}
                        />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <GddControls.GddTextField
                        fullWidth={true}
                        name="description"
                        label="Description"
                        required={false}
                        value={values.description}
                        onChange={handleInputChange}
                        error={errors.description}
                    />
                </Grid>
                {isAdmin && (
                    <Grid item xs={12}>
                        <GddControls.GddSelect
                            fullWidth={true}
                            name="airlineId"
                            label="Airline"
                            required={true}
                            options={airlines}
                            value={values.airlineId}
                            onChange={handleInputChange}
                            error={errors.airlineId}
                        />
                    </Grid>
                )}
                {!b787Show && !editMode && (
                    <Grid item xs={12}>
                        {}

                        <GddControls.GddTextField
                            fullWidth={true}
                            name="dataFrameFile"
                            ref={fileInput}
                            //label="Data Frame Input"
                            type="file"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            required={false}
                            // onChange={(e) => {
                            //     setUploadFile(e.target.files[0]);
                            // }}
                            value={values.dataFrameFile}
                            onChange={(e) => {
                                handleInputChange(e);
                                setUploadFile(e.target.files[0]);
                            }}
                            error={errors.dataFrameFile}
                        />
                    </Grid>
                )}

                {b787Show && (
                    <Grid item xs={12}>
                        <GddDataTableWithAutoEdit
                            title={tableTitle}
                            cols={cols}
                            data={b787RulesData}
                            bodyHeightLimit="175px"
                            setData={setB787RulesData}
                            onAddRow={onAddRule}
                            onDeleteRow={onDeleteRule}
                            onUpdateRow={onUpdateRule}
                        />
                    </Grid>
                )}

                {b787Show && editMode && (
                    <Grid item xs={12}>
                        <Stack direction="row" spacing={2}>
                            <GddWarningIcon
                                iconSize="large"
                                iconColor="warning"
                            />
                            <Typography
                                variant="subtitle2"
                                display="block"
                                paragraph
                                gutterBottom
                            >
                                Note: Any changes to this B787 Report Data Frame
                                must be reflected in any reports associated with
                                it.
                            </Typography>
                        </Stack>
                    </Grid>
                )}
            </Grid>
            <GddControls.GddSnackbar
                open={openSnackbar}
                message={snackbarMsg}
                onClose={() =>
                    setSnackbarState({ ...snackbarState, openSnackbar: false })
                }
                severity={snackbarSeverity}
            />
        </GddDialog>
    );
}

export default DataFrameDetails;
