import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import GddControls from "../../../components/gdd-controls/GddControls";
import GddDialog from "../../../components/gdd-dialog/GddDialog";
import GddDialogTitle from "../../../components/gdd-dialog/GddDialogTitle";
import AircraftTypesIcon from "../../../icons/admin/AircraftTypesIcon";
import useAxios from "../../../api/useAxios";

function AircraftTypeDetails(props) {
    const {
        open,
        setOpen,
        addOrEdit,
        values,
        setValues,
        setErrors,
        errors,
        handleInputChange,
        resetForm,
        validate,
        editMode,
    } = props;

    const { ENDPOINTS, createApiEndPoint, axiosApiInstance } = useAxios();

    const iconType = <AircraftTypesIcon iconSize="medium" />;
    const dlgTitle = (
        <GddDialogTitle title={editMode ? "Edit Aircraft Type" : "Create New Aircraft Type"} titleIcon={iconType} />
    );

    const [manufacturers, setManufacturers] = useState([]);

    useEffect(() => {
        createApiEndPoint(ENDPOINTS.MANUFACTURERS)
            .fetchAll("getPage?pagedSearch.page=1&pagedSearch.pageSize=100")
            .then((res) => {
                const manufacturerData = res.data.items;
                let manufacturerOptions = manufacturerData.sort((a, b) => (a.name > b.name) ? 1 : -1).map((item) => ({
                    id: item.id,
                    title: item.name,
                }));
                setManufacturers(manufacturerOptions);
            })
            .catch((err) => console.log(err));
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            addOrEdit(values, editMode);
        }
    };

    return (
        <div>
            <GddDialog
                title={dlgTitle}
                open={open}
                setOpen={setOpen}
                handleSubmit={handleSubmit}
                resetForm={resetForm}
            >
                <Grid
                    container
                    rowSpacing={2}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                    <Grid item xs={6}>
                        <GddControls.GddTextField
                            fullWidth={true}
                            name="name"
                            variant="outlined"
                            label="Name"
                            required={true}
                            value={values.name}
                            onChange={handleInputChange}
                            error={errors.name}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <GddControls.GddTextField
                            fullWidth={true}
                            name="description"
                            label="Description"
                            required={false}
                            value={values.description}
                            onChange={handleInputChange}
                            error={errors.description}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <GddControls.GddSelect
                            fullWidth={true}
                            name="manufacturerId"
                            label="Airframer"
                            required={true}
                            options={manufacturers}
                            value={values.manufacturerId}
                            onChange={handleInputChange}
                            error={errors.manufacturerId}
                        />
                    </Grid>
                </Grid>
            </GddDialog>
        </div>
    );
}

export default AircraftTypeDetails;
