import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import GddControls from "../../components/gdd-controls/GddControls";
import GddDialog from "../../components/gdd-dialog/GddDialog";
import GddDialogTitle from "../../components/gdd-dialog/GddDialogTitle";
import EndPointIcon from "../../../src/icons/param-mapping/EndPointIcon";
import useAxios from "../../api/useAxios";

function EndPointDetails(props) {
    const {
        open,
        setOpen,
        addOrEdit,
        values,
        setValues,
        setErrors,
        errors,
        handleInputChange,
        setSelectedTransferTypeId,
        resetForm,
        validate,
        editMode,
        setFpsShow,
        fpsShow,
        setSftpShow,
        sftpShow,
        setAwsShow,
        awsShow,
        handleShow,
        resetValues,
    } = props;

    const { ENDPOINTS, createApiEndPoint, axiosApiInstance } = useAxios();

    const iconType = <EndPointIcon iconSize="medium" />;
    const dlgTitle = (
        <GddDialogTitle
            title={editMode ? "Edit Endpoint" : "Create New Endpoint"}
            titleIcon={iconType}
        />
    );

    const [transferTypes, setTransferTypes] = useState([]);
    const [awsRegions, setAwsRegions] = useState([]);
    const [AuthenticateWithSshKey, setAuthenticateWithSshKey] = useState(false);
   

    useEffect(() => {
        createApiEndPoint(ENDPOINTS.TRANSFERTYPES)
            .fetchAll("getAll")
            .then((res) => {
                const transferTypeData = res.data;
                let transferTypeOptions = transferTypeData
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((item) => ({
                        id: item.id,
                        title: item.name,
                    }));
                setTransferTypes(transferTypeOptions);
            })
            .catch((err) => console.log(err));

        createApiEndPoint(ENDPOINTS.AWSCONFIG)
            .fetchAll("getRegions")
            .then((res) => {
                const awsRegionsData = res.data;
                let awsRegionOptions = awsRegionsData.map((item) => ({
                    id: item.id,
                    title: item.name,
                }));
                setAwsRegions(awsRegionOptions);
            })
            .catch((err) => console.log(err));
           
    }, []);

   
    useEffect(() => (values.AuthenticateWithSshKey = AuthenticateWithSshKey), [AuthenticateWithSshKey]);
    
    const handleSubmit = (e) => {
        e.preventDefault();

        if (validate()) {
            addOrEdit(values, editMode);
        }
    };

    return (
        <div>
            <GddDialog
                title={dlgTitle}
                open={open}
                setOpen={setOpen}
                handleSubmit={handleSubmit}
                resetForm={resetForm}
               
            >
                <Grid
                    container
                    rowSpacing={2}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                    <Grid item xs={6}>
                        <GddControls.GddTextField
                            fullWidth={true}
                            name="name"
                            variant="outlined"
                            label="Name"
                            required={true}
                            value={values.name}
                            onChange={handleInputChange}
                            error={errors.name}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <GddControls.GddSelect
                            fullWidth={true}
                            name="transferTypeId"
                            label="Transfer Type"
                            required={true}
                            options={transferTypes}
                            value={values.transferTypeId}
                            onChange={(e) => {
                                resetValues();
                                handleInputChange(e);
                                setSelectedTransferTypeId(e.target.value);
                                handleShow(e.target.value);
                               // setShowPKC(false);
                                
                            }}
                            error={errors.transferTypeId}
                        />
                    </Grid>

                    {(fpsShow || sftpShow) && (
                        <Grid item xs={6}>
                            <GddControls.GddTextField
                                fullWidth={true}
                                name="ipAddress"
                                variant="outlined"
                                label="IP Address/URL"
                                required={true}
                                value={values.ipAddress}
                                onChange={handleInputChange}
                                error={errors.ipAddress}
                            />
                        </Grid>
                    )}

                    {(fpsShow || sftpShow) && (
                        <Grid item xs={6}>
                            <GddControls.GddTextField
                                fullWidth={true}
                                name="port"
                                variant="outlined"
                                label="Port"
                                required={true}
                                value={values.port}
                                onChange={handleInputChange}
                                error={errors.port}
                            />
                        </Grid>
                    )}

                    {(fpsShow || sftpShow) && (
                        <Grid item xs={6}>
                            <GddControls.GddTextField
                                fullWidth={true}
                                name="userName"
                                variant="outlined"
                                label="Username"
                                required={true}
                                value={values.userName}
                                onChange={handleInputChange}
                                error={errors.userName}
                            />
                        </Grid>
                    )}

                    {(fpsShow || sftpShow) && (
                        <Grid item xs={6}>
                            <GddControls.GddTextField
                                fullWidth={true}
                                name="password"
                                variant="outlined"
                                label="Password"
                                type="password"
                                required={true}
                                value={values.password}
                                onChange={handleInputChange}
                                error={errors.password}
                            />
                        </Grid>
                    )}

                    {(fpsShow || sftpShow) && (
                        <Grid item xs={6}>
                            <GddControls.GddTextField
                                fullWidth={true}
                                name="folderPath"
                                variant="outlined"
                                label="Folder Path"
                                required={true}
                                value={values.folderPath}
                                onChange={handleInputChange}
                                error={errors.folderPath}
                            />
                        </Grid>
                    )}

                    {awsShow && (
                        <Grid item xs={6}>
                            <GddControls.GddTextField
                                fullWidth={true}
                                name="bucket"
                                variant="outlined"
                                label="Bucket"
                                required={true}
                                value={values.bucket}
                                onChange={handleInputChange}
                                error={errors.bucket}
                            />
                        </Grid>
                    )}

                    {awsShow && (
                        <Grid item xs={6}>
                            <GddControls.GddSelect
                                fullWidth={true}
                                name="region"
                                variant="outlined"
                                label="Region"
                                required={true}
                                options={awsRegions}
                                optionId={false}
                                value={values.region}
                                onChange={handleInputChange}
                                error={errors.region}
                            />
                        </Grid>
                    )}

                    {awsShow && (
                        <Grid item xs={6}>
                            <GddControls.GddTextField
                                fullWidth={true}
                                name="accessKey"
                                variant="outlined"
                                label="Access Key"
                                required={true}
                                value={values.accessKey}
                                onChange={handleInputChange}
                                error={errors.accessKey}
                            />
                        </Grid>
                    )}

                    {awsShow && (
                        <Grid item xs={6}>
                            <GddControls.GddTextField
                                fullWidth={true}
                                name="secretKey"
                                variant="outlined"
                                label="Secret Key"
                                required={true}
                                value={values.secretKey}
                                onChange={handleInputChange}
                                error={errors.secretKey}
                            />
                        </Grid>
                    )}

                    {awsShow && (
                        <Grid item xs={6}>
                            <GddControls.GddSelect
                                fullWidth={true}
                                name="encryptionType"
                                variant="outlined"
                                label="Encryption Type"
                                required={true}
                                options={[
                                    { id: 0, title: "Default" },
                                    { id: 1, title: "AES256" },
                                    { id: 2, title: "AWSKMS" },
                                ]}
                                optionId={false}
                                value={values.encryptionType}
                                onChange={handleInputChange}
                                error={errors.encryptionType}
                            />
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <GddControls.GddTextField
                            fullWidth={true}
                            name="description"
                            label="Description"
                            required={false}
                            value={values.description}
                            onChange={handleInputChange}
                            error={errors.description}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <GddControls.GddCheckbox
                            fullWidth={true}
                            name="isEnabled"
                            label="Enable"
                            value={values.isEnabled}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    {sftpShow && (
                        <Grid item xs={6}>
                            <GddControls.GddCheckbox
                                fullWidth={true}
                                name="AuthenticateWithSshKey"
                                label="Authenticate using SSH key"
                                value={values.AuthenticateWithSshKey}
                                onChange={(e) => {
                                    setAuthenticateWithSshKey(e.target.value);
                                    handleInputChange(e);
                                }}
                            />
                        </Grid>
                    )}
                    
                </Grid>
            </GddDialog>
        </div>
    );
}

export default EndPointDetails;
