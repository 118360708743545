import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { IconButton } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";

const Input = styled("input")({
    display: "none",
});

function GddFileUpload() {
    return (
        <label htmlFor="contained-button-file">
            <Input accept="image/*" id="contained-button-file" type="file" />
            {/* <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
            >
                <UploadFileIcon />
            </IconButton> */}
            <Button
                variant="contained"
                component="span"
                sx={{ height: "inherit" }}
                color="inherit"
            >
                Upload
            </Button>
        </label>
    );
}

export default GddFileUpload;
