import { useConfirm } from "material-ui-confirm";
import React, { useEffect, useRef, useState } from "react";
import useAxios from "../../api/useAxios";
import GddControls from "../../components/gdd-controls/GddControls";
import { useGddForm } from "../../components/gdd-form/UseGddForm";
import GddTable from "../../components/gdd-table/GddTable";
import TableTitle from "../../components/gdd-table/TableTitle";
import RecorderConfigsIcon from "../../icons/recorderconfigs/RecorderConfigsIcon";

import RecorderConfigDetails from "./RecorderConfigDetails";

const initialFieldValues = {
    id: 0,
    name: "",
    airlineId: "",
    recorderTypeId: "",
    recorderFormatId: "",
    subFrameSize: "",
    miniFrames: "",
    blockModeId: "",
    customRegex: "",
    dataPacking: "",
    isL3_FA1000: false,
    isMfs: false,
    isTdyNoTags: false,
};

function RecorderConfigsPage() {
    const { ENDPOINTS, createApiEndPoint, axiosApiInstance } = useAxios();
    const validate = (fieldValues = values) => {
        // Update error only for the given property.
        let temp = { ...errors };

        if ("name" in fieldValues)
            temp.name = fieldValues.name !== "" ? "" : "Name is required";

        if ("airlineId" in fieldValues)
            temp.airlineId =
                fieldValues.airlineId !== "" ? "" : "Airline is required";

        if ("recorderTypeId" in fieldValues)
            temp.recorderTypeId =
                fieldValues.recorderTypeId !== ""
                    ? ""
                    : "Recorder Type is required";
        if ("recorderFormatId" in fieldValues)
            temp.recorderFormatId =
                fieldValues.recorderFormatId !== ""
                    ? ""
                    : "Recording Format is required";

        if ("subFrameSize" in fieldValues)
            temp.subFrameSize =
                fieldValues.subFrameSize !== ""
                    ? ""
                    : "Subframe Size is required";

        if ("isMfs" in fieldValues && fieldValues.isMfs === true) {
            if ("miniFrames" in fieldValues)
                temp.miniFrames =
                    fieldValues.miniFrames !== ""
                        ? ""
                        : "Miniframe is required";
        } else {
            temp.miniFrames = "";
        }

        if ("isL3_FA1000" in fieldValues && fieldValues.isL3_FA1000 === true) {
            if ("blockModeId" in fieldValues)
                temp.blockModeId =
                    fieldValues.blockModeId !== ""
                        ? ""
                        : "Block Mode is required";
        } else {
            temp.blockModeId = "";
        }

        if ("isTdyNoTags" in fieldValues && fieldValues.isTdyNoTags === true) {
            if ("dataPacking" in fieldValues)
                temp.dataPacking =
                    fieldValues.dataPacking !== ""
                        ? ""
                        : "Data Packing is required";
        } else {
            temp.dataPacking = "";
        }

        setErrors({ ...temp });
        // Return the following only in case of whole form validation (not single form validation)
        if (fieldValues === values)
            return Object.values(temp).every((x) => x === "");
    };

    const confirm = useConfirm();

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm,
        editMode,
        setEditMode,
    } = useGddForm(initialFieldValues, validate, true);

    const iconType = <RecorderConfigsIcon iconSize="medium" />;
    const tableTitle = (
        <TableTitle title="Recorder Configurations" titleIcon={iconType} />
    );

    const [openPopup, setopenPopup] = useState(false);

    const [snackbarState, setSnackbarState] = useState({
        openSnackbar: false,
        snackbarMsg: "",
        snackbarSeverity: "success",
    });

    const { openSnackbar, snackbarMsg, snackbarSeverity } = snackbarState;
    const [fetchCount, setFetchCount] = useState(0);

    const tableRef = useRef();

    const [defaultFilters, setDefaultFilters] = useState({});

    const getDefaultFilter = (columnName) => {
        if (defaultFilters[columnName]) return defaultFilters[columnName];
        else return null;
    };

    const cols = [
        {
            title: "Id",
            field: "id",
            filterPlaceholder: "Recorder Id",
            hidden: true,
        },

        {
            title: "Name",
            field: "name",
            filterPlaceholder: "Filter by Name",
        },

        {
            title: "Airline",
            field: "airline",
            filterPlaceholder: "Filter by Airline",
        },
    ];

    cols.forEach((item) => (item.defaultFilter = getDefaultFilter(item.field)));

    useEffect(() => {
        tableRef.current.onQueryChange();
    }, [fetchCount]);

    const addOrEdit = (recConfig, isEditMode) => {
        let newRecConfig = {
            id: recConfig.id,
            name: recConfig.name,
            airlineId: recConfig.airlineId,
            recorderTypeId: recConfig.recorderTypeId,
            recorderFormatId: recConfig.recorderFormatId,
            subFrameSize: recConfig.subFrameSize,
            miniFrames: recConfig.miniFrames,
            blockModeId: recConfig.blockModeId,
            dataPacking: recConfig.dataPacking,
            customRegexDescription: recConfig.customRegex,
            isL3_FA1000: false,
            isMfs: false,
        };

        if (!isEditMode) {
            createRecConfig(newRecConfig);
        } else {
            updateRecConfig(newRecConfig);
        }
    };

    const createRecConfig = (recConfig) => {
        console.log(recConfig);
        createApiEndPoint(ENDPOINTS.RECORDERCONFIGURATION)
            .create(recConfig)
            .then((res) => {
                resetForm();
                setopenPopup(false);
                //setData([...data, res.data]);
                setFetchCount(fetchCount + 1);
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: "Successfully created Record Configuration",
                    snackbarSeverity: "success",
                });
            })
            .catch((error) => {
                console.log(error.response);
                let msg = "Failed to create Record Configuration: ";
                if (error.response && error.response.data) {
                    if (error.response.data.exceptionMessage) {
                        msg = error.response.data.exceptionMessage;
                    } else {
                        msg = error.response.data.message;
                    }
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const updateRecConfig = (recConfig) => {
        createApiEndPoint(ENDPOINTS.RECORDERCONFIGURATION)
            .update(recConfig.id, recConfig)
            .then((res) => {
                resetForm();
                setopenPopup(false);
                // let updatedData = data.map(
                //     (obj) => [res.data].find((o) => o.id === obj.id) || obj
                // );
                // setData(updatedData);
                setFetchCount(fetchCount + 1);
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: "Successfully updated Record Configuration",
                    snackbarSeverity: "success",
                });
            })
            .catch((error) => {
                let msg = "Failed to update Record Configuration: ";
                if (error.response && error.response.data) {
                    msg = error.response.data.message;
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }

                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const deleteRecConfig = (recConfig) => {
        createApiEndPoint(ENDPOINTS.RECORDERCONFIGURATION)
            .delete(recConfig.id)
            .then((res) => {
                // let updatedData = data.filter((obj) => obj.id !== airline.id);
                // setData(updatedData);
                setFetchCount(fetchCount + 1);
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: "Successfully deleted Record Configuration",
                    snackbarSeverity: "success",
                });
            })
            .catch((error) => {
                let msg = "Failed to delete Record Configuration: ";
                if (error.response && error.response.data) {
                    msg = error.response.data.message;
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }

                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const addClicked = () => {
        resetForm();
        setopenPopup(true);
        setEditMode(false);
        setopenPopup(true);
    };

    const editClicked = (selectedRecConfig) => {
        resetForm();
        createApiEndPoint(ENDPOINTS.RECORDERCONFIGURATION)
            .fetchById(selectedRecConfig.id)
            .then((res) => {
                const recConfig = res.data;
               
                setValues({
                    ...values,
                    id: recConfig.id,
                    name: recConfig.name,
                    airlineId: recConfig.airlineId,
                    recorderTypeId: recConfig.recorderTypeId,
                    recorderFormatId: recConfig.recorderFormatId,
                    subFrameSize: recConfig.subFrameSize,
                    miniFrames: recConfig.miniFrames,
                    blockModeId: recConfig.blockModeId,
                    dataPacking: recConfig.dataPacking,
                    customRegex: recConfig.customRegexDescription,
                    isL3_FA1000:
                        recConfig.recorderType &&
                        recConfig.recorderType?.type === "L3_FA1000"
                            ? true
                            : false,
                    isMfs:
                        recConfig.recorderFormat &&
                        recConfig.recorderFormat?.name.includes("_No_Mfs")
                            ? false
                            : true,
                    isTdyNoTags:
                        recConfig.recorderType &&
                        recConfig.recorderType?.type === "TDY_NoTags"
                            ? true
                            : false,
                });
                setEditMode(true);
                setopenPopup(true);
            })
            .catch((error) => {
                let msg = "Failed to fetch Aircraft: ";
                if (error.response && error.response.data) {
                    msg = error.response.data.message;
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }

                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const deleteClicked = (recConfig) => {
        confirm({
            description: `Are you sure you want to delete the Record Configuration '${recConfig.name}?'`,
        })
            .then(() => deleteRecConfig(recConfig))
            .catch(() => {});
    };

    return (
        <div>
            <RecorderConfigDetails
                open={openPopup}
                setOpen={setopenPopup}
                addOrEdit={addOrEdit}
                {...{
                    values,
                    setValues,
                    errors,
                    setErrors,
                    handleInputChange,
                    resetForm,
                    validate,
                    editMode,
                }}
            />

            <GddTable
                tableRef={tableRef}
                title={tableTitle}
                cols={cols}
                fetchEndPoint={ENDPOINTS.RECORDERCONFIGURATION}
                saveFilters={setDefaultFilters}
                baseUrlExt="getPage"
                pagedSearchString="pagedSearch"
                onAdd={addClicked}
                onEdit={editClicked}
                onDelete={deleteClicked}
            ></GddTable>

            <GddControls.GddSnackbar
                open={openSnackbar}
                message={snackbarMsg}
                onClose={() =>
                    setSnackbarState({ ...snackbarState, openSnackbar: false })
                }
                severity={snackbarSeverity}
            />
        </div>
    );
}

export default RecorderConfigsPage;
