import React, { useEffect, useState, useRef } from "react";
import GddTable from "../../components/gdd-table/GddTable";
import TableTitle from "../../components/gdd-table/TableTitle";
import DataFrameIcom from "../../icons/param-mapping/DataFramesIcon";
import { useGddForm } from "../../components/gdd-form/UseGddForm";
import GddControls from "../../components/gdd-controls/GddControls";
import useAxios from "../../api/useAxios";
import { useConfirm } from "material-ui-confirm";
import DataFrameDetails from "./DataFrameDetails";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import GddDialogTitle from "../../components/gdd-dialog/GddDialogTitle";

const initialFieldValues = {
    id: 0,
    name: "",
    description: "",
    dataFrameTypeId: "",
    airlineId: "",
    dataFrameFile: "",
    FrameXml: "",
};

function DataFramesPage() {
    const { ENDPOINTS, createApiEndPoint, axiosApiInstance } = useAxios();

    const validate = (fieldValues = values) => {
        // Update error only for the given property.
        let temp = { ...errors };

        if ("name" in fieldValues)
            temp.name = fieldValues.name !== "" ? "" : "Name is required";

        if ("airlineId" in fieldValues)
            temp.airlineId =
                fieldValues.airlineId !== "" ? "" : "Airline is required";

        setErrors({ ...temp });

        // Return the following only in case of whole form validation (not single form validation)
        if (fieldValues === values)
            return Object.values(temp).every((x) => x === "");
    };

    const confirm = useConfirm();

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm,
        editMode,
        setEditMode,
    } = useGddForm(initialFieldValues, validate, true);

    const iconType = <DataFrameIcom iconSize="medium" />;
    const tableTitle = <TableTitle title="Data Frames" titleIcon={iconType} />;

    const confirmSaveIcon = <HelpOutlineOutlinedIcon color="secondary" />;

    const [openPopup, setopenPopup] = useState(false);
    const [snackbarState, setSnackbarState] = useState({
        openSnackbar: false,
        snackbarMsg: "",
        snackbarSeverity: "success",
    });
    const { openSnackbar, snackbarMsg, snackbarSeverity } = snackbarState;
    const [fetchCount, setFetchCount] = useState(0);

    const [b787Show, setB787Show] = useState(false);
    const [b787RulesData, setB787RulesData] = React.useState([]);

    const tableRef = useRef();

    const [defaultFilters, setDefaultFilters] = useState({});

    const getDefaultFilter = (columnName) => {
        if (defaultFilters[columnName]) return defaultFilters[columnName];
        else return null;
    };

    const cols = [
        {
            title: "Id",
            field: "id",
            filterPlaceholder: "Filter by Id",
            hidden: true,
        },
        {
            title: "Name",
            field: "name",
            filterPlaceholder: "Filter by Name",
        },
        {
            title: "Description",
            field: "description",
            filterPlaceholder: "Filter by Description",
        },
        {
            title: "Provided File Name",
            field: "providedFileName",
            filterPlaceholder: "Filter by Provided File Name",
        },
        {
            title: "Data Frame Type",
            field: "dataFrameType",
            filterPlaceholder: "Filter by Data Frame Type",
        },
    ];

    cols.forEach((item) => (item.defaultFilter = getDefaultFilter(item.field)));

    const [dataFrames, setDataFrames] = useState([]);

    useEffect(() => {
        tableRef.current.onQueryChange();
    }, [fetchCount]);

    useEffect(() => {
        createApiEndPoint(ENDPOINTS.DATAFRAMETYPES)
            .fetchAll("getKeyValuePairs")
            .then((res) => {
                setDataFrames(res.data);
            })
            .catch((err) => console.log(err));
    }, []);

    const addOrEdit = (dataFrame, isEditMode, formData) => {
        if (b787Show) {
            dataFrame.FrameXml = JSON.stringify(b787RulesData);
        } else {
            dataFrame.FrameXml = "";
        }

        if (!isEditMode) {
            dataFrame.dataFrameTypeId = determineDataFrameTypeId(dataFrame);
            createDataFrame(dataFrame, formData);
        } else {
            updateDataFrame(dataFrame);
        }
    };

    const determineDataFrameTypeId = (dataFrame) => {
        let index = -1;

        if (dataFrame.FrameXml !== "") {
            index = dataFrames.findIndex((item) => item.name.includes("B787"));
        } else {
            let extension = dataFrame.dataFrameFile.split(".").pop();
            index = dataFrames.findIndex((item) =>
                item.name.includes(extension.toUpperCase())
            );
        }

        if (index >= 0) return dataFrames[index].id;
        // unable to determine
        else return -1;
    };

    const createDataFrame = (dataFrame, formData) => {
        //
        //  First upload the prm xml file.
        //
        createApiEndPoint(ENDPOINTS.DATAFRAME)
            .upload("UploadXml", formData, (progress) => {
                console.log(progress);
            })
            .then((res) => {
                //
                // File upload success. Now post the entity
                //
                dataFrame.providedFileName = res.data.originalFileName;
                dataFrame.storedFileName = res.data.newFileName;
                createApiEndPoint(ENDPOINTS.DATAFRAME)
                    .create(dataFrame)
                    .then((res) => {
                        resetForm();
                        setopenPopup(false);
                        setFetchCount(fetchCount + 1);
                        setSnackbarState({
                            ...snackbarState,
                            openSnackbar: true,
                            snackbarMsg: "Successfully created Data Frame",
                            snackbarSeverity: "success",
                        });
                    })
                    .catch((error) => {
                        let msg = "Failed to create Data Frame: ";
                        if (error.response && error.response.data) {
                            msg = error.response.data.message;
                        } else if (error.request) {
                            msg = msg + error.request;
                        } else {
                            msg = msg + "Error: " + error.message;
                        }

                        setSnackbarState({
                            ...snackbarState,
                            openSnackbar: true,
                            snackbarMsg: msg,
                            snackbarSeverity: "error",
                        });
                    });
            })
            .catch((error) => {
                let msg = "Failed to upload PRM file for Data Frame: ";
                if (error.response && error.response.data) {
                    msg = error.response.data.message;
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }

                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const updateDataFrame = (dataFrame) => {
        createApiEndPoint(ENDPOINTS.DATAFRAME)
            .update(dataFrame.id, dataFrame)
            .then((res) => {
                resetForm();
                setopenPopup(false);
                setFetchCount(fetchCount + 1);
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: "Successfully updated Data Frame",
                    snackbarSeverity: "success",
                });
            })
            .catch((error) => {
                let msg = "Failed to update Data Frame: ";
                if (error.response && error.response.data) {
                    msg = error.response.data.message;
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const deleteDataFrame = (dataFrame) => {
        createApiEndPoint(ENDPOINTS.DATAFRAME)
            .delete(dataFrame.id)
            .then((res) => {
                setFetchCount(fetchCount + 1);
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: "Successfully deleted Data Frame",
                    snackbarSeverity: "success",
                });
            })
            .catch((error) => {
                let msg = "Failed to delete Data Frame: ";
                if (error.response && error.response.data) {
                    msg = error.response.data.message;
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }

                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const parseJson = (json) => {
        if (json === undefined || json === "") return [];
        else return JSON.parse(json);
    };

    const addClicked = () => {
        resetForm();
        setEditMode(false);
        setB787Show(false);
        setB787RulesData([]);
        setopenPopup(true);
    };

    const editClicked = (selectedDF) => {
        resetForm();
        createApiEndPoint(ENDPOINTS.DATAFRAME)
            .fetchById(selectedDF.id)
            .then((res) => {
                const dataFrame = res.data;
                setValues({
                    ...values,
                    id: selectedDF.id,
                    name: dataFrame.name,
                    description: dataFrame.description,
                    dataFrameTypeId: dataFrame.dataFrameTypeId,
                    airlineId: dataFrame.airlineId,
                    providedFileName: dataFrame.providedFileName,
                    storedFileName: dataFrame.storedFileName,
                });
                let isB787 = dataFrames
                    .find((item) => item.id === dataFrame.dataFrameTypeId)
                    .name.includes("B787");
                setB787RulesData(isB787 ? parseJson(dataFrame.frameXml) : []);
                setEditMode(true);
                setB787Show(isB787);
                setopenPopup(true);
            })
            .catch((error) => {
                let msg = "Failed to fetch Data Frame: ";
                if (error.response && error.response.data) {
                    msg = error.response.data.message;
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }

                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const deleteClicked = (dataFrame) => {
        confirm({
            description: `Are you sure you want to delete the Data Frame '${dataFrame.name}'?`,
        })
            .then(() => deleteDataFrame(dataFrame))
            .catch(() => {});
    };

    return (
        <div>
            <DataFrameDetails
                open={openPopup}
                setOpen={setopenPopup}
                addOrEdit={addOrEdit}
                isAdmin={true}
                setB787Show={setB787Show}
                b787Show={b787Show}
                b787RulesData={b787RulesData}
                setB787RulesData={setB787RulesData}
                {...{
                    values,
                    setValues,
                    errors,
                    setErrors,
                    handleInputChange,
                    resetForm,
                    validate,
                    editMode,
                }}
            />
            <GddTable
                tableRef={tableRef}
                title={tableTitle}
                cols={cols}
                fetchEndPoint={ENDPOINTS.DATAFRAME}
                saveFilters={setDefaultFilters}
                baseUrlExt="getPageNoRedaction"
                pagedSearchString="pagedSearch"
                onAdd={addClicked}
                onEdit={editClicked}
                onDelete={deleteClicked}
                options={{ exportFileName: "DataFrames" }}
            ></GddTable>
            <GddControls.GddSnackbar
                open={openSnackbar}
                message={snackbarMsg}
                onClose={() =>
                    setSnackbarState({ ...snackbarState, openSnackbar: false })
                }
                severity={snackbarSeverity}
            />
        </div>
    );
}

export default DataFramesPage;
