import React from "react";
import Button from "@mui/material/Button";

function GddButton(props) {
    const { text, size, color, disabled, variant, onClick, ...other } = props;

    return (
        <Button
            sx={{ textTransform: "none", margin: "2px" }}
            variant={variant || "contained"}
            size={size || "small"}
            color={color || "primary"}
            disabled={disabled}
            onClick={onClick}
            {...other}
        >
            {text}
        </Button>
    );
}

export default GddButton;
