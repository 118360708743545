import React from "react";
import { Grid, Typography } from "@mui/material";

function GddDialogTitle({ title, titleIcon }) {
    return (
        <Grid display="flex">
            <Grid item style={{ padding: 4 }}>
                {titleIcon}
            </Grid>
            <Grid item display="flex 1">
                <Typography
                    variant="h6"
                    component="span"
                    style={{ paddingLeft: 15 }}
                >
                    {title}
                </Typography>
            </Grid>
        </Grid>
    );
}

export default GddDialogTitle;
