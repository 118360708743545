import React from "react";

import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";

function AdminIcon({ iconColor, iconSize = "small", ...other }) {
    return (
        <AdminPanelSettingsIcon
            color={iconColor}
            fontSize={iconSize}
            {...other}
        />
    );
}

export default AdminIcon;
