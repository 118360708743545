import React, { useRef, useEffect } from "react";

import { Grid } from "@mui/material";
import GddControls from "./GddControls";

function GddTransferTable(props) {
    const {
        title,
        cols,
        leftData,
        rightData,
        setLeftData,
        setRightData,
        xferTableRefresher,
        setXferTableRefresher,
    } = props;

    // const [left, setLeft] = React.useState(leftData);
    //const [right, setRight] = React.useState(rightData);

    const getSubHeaderText = (selCount, items) => {
        if (items) {
            return `${selCount} of ${items.length} selected`;
        } else {
            return `0 of 0 selected`;
        }
    };

    const [leftSubHeaderText, setLeftSubHeaderText] = React.useState(
        getSubHeaderText(0, leftData)
    );

    const [rightSubHeaderText, setRightSubHeaderText] = React.useState(
        getSubHeaderText(0, rightData)
    );

    const tblCols = [
        {
            title: "Name",
            field: "label",
            filterPlaceholder: "Filter by Name",
        },
        {
            title: "Description",
            field: "description",
            filterPlaceholder: "Filter by Description",
        },
    ];

    const onLeftTableSelectionChange = (rows) => {
        if (rows) {
            const subHeaderText = getSubHeaderText(rows.length, leftData);
            setLeftSubHeaderText(subHeaderText);
        } else {
            const subHeaderText = getSubHeaderText(0, leftData);
            setLeftSubHeaderText(subHeaderText);
        }
    };

    const onRightTableSelectionChange = (rows) => {
        if (rows) {
            const subHeaderText = getSubHeaderText(rows.length, rightData);
            setRightSubHeaderText(subHeaderText);
        } else {
            const subHeaderText = getSubHeaderText(0, rightData);
            setRightSubHeaderText(subHeaderText);
        }
    };

    const AminusB = (a, b) => {
        let res = [];
        a.forEach((item) => {
            if (!b.some((x) => x.label === item.label)) {
                res.push(item);
            }
        });
        return res;
    };

    const AunionB = (a, b) => {
        let res = [...new Set([...a, ...b])];
        return res;
    };

    const onLeftTableMoveClicked = (rows) => {
        let selectedData = rows.map((item) => ({
            id: item.id,
            label: item.label,
            description: item.description,
        }));

        let rData = AunionB(rightData, selectedData);
        let lData = AminusB(leftData, selectedData);

        setLeftData(lData);
        setRightData(rData);

        if (leftTableRef.current) {
            leftTableRef.current.onAllSelected(false);
        }
        if (rightTableRef.current) {
            rightTableRef.current.onAllSelected(false);
        }
    };

    const onRightTableMoveClicked = (rows) => {
        let selectedData = rows.map((item) => ({
            id: item.id,
            label: item.label,
            description: item.description,
        }));

        let lData = AunionB(leftData, selectedData);
        let rData = AminusB(rightData, selectedData);

        setLeftData(lData);
        setRightData(rData);

        if (leftTableRef.current) {
            leftTableRef.current.onAllSelected(false);
        }
        if (rightTableRef.current) {
            rightTableRef.current.onAllSelected(false);
        }
    };

    const leftTableRef = useRef(null);
    const rightTableRef = useRef(null);

    useEffect(() => {
        // Called when the data in the right table is set from outside
        //(on update of xferTableRefresher)
        let lData = AminusB(leftData, rightData);
        setLeftData(lData);

        const subHeaderText = getSubHeaderText(0, lData);
        setLeftSubHeaderText(subHeaderText);
    }, [xferTableRefresher]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <GddControls.GddMiniTable
                    tableRef={leftTableRef}
                    title="Excluded Parameters"
                    subHeaderText={leftSubHeaderText}
                    cols={tblCols}
                    items={leftData}
                    isLeftTable={true}
                    onSelectionChange={onLeftTableSelectionChange}
                    onMoveClicked={onLeftTableMoveClicked}
                />
            </Grid>
            <Grid item xs={6}>
                <GddControls.GddMiniTable
                    tableRef={rightTableRef}
                    title="Included Parameters"
                    subHeaderText={rightSubHeaderText}
                    cols={tblCols}
                    items={rightData}
                    isLeftTable={false}
                    onSelectionChange={onRightTableSelectionChange}
                    onMoveClicked={onRightTableMoveClicked}
                />
            </Grid>
        </Grid>
    );
}

export default GddTransferTable;
