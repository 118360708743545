import React from "react";

import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

function AddminAccountIcon({ iconColor, iconSize = "small", ...other }) {
    return (
        <ManageAccountsIcon color={iconColor} fontSize={iconSize} {...other} />
    );
}

export default AddminAccountIcon;
