import React, { useContext } from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
import styled from "styled-components/";
import { Tooltip } from "@mui/material";
import { AuthContext } from "../../auth/AuthContext";
import GddHomeIcon from "../../icons/Home/GddHomeIcon";

const ColorLink = styled(Link)``;

function HomeNav({ iconColor, routeTo, showToolTip }) {
    const { loggedInUser } = useContext(AuthContext);

    const isAdmin = loggedInUser.role === "Admin";

    console.log(iconColor);
    return (
        <div>
            <List
                sx={{
                    width: "100%",
                    maxWidth: 360,
                    bgcolor: "background.paper",
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
            >
                <ColorLink
                    style={{ textDecoration: "none", color: "inherit" }}
                    to="/home"
                >
                    <Tooltip title={showToolTip ? "Home" : ""} arrow={true}>
                        <ListItemButton sx={{ pl: 2 }}>
                            <ListItemIcon>
                                <GddHomeIcon />
                            </ListItemIcon>
                            <ListItemText primary="Home" />
                        </ListItemButton>
                    </Tooltip>
                </ColorLink>
            </List>
        </div>
    );
}

export default HomeNav;
