import { ListItemIcon, MenuItem } from "@mui/material";
import React, { useState } from "react";
import GddPasswordIcon from "../../icons/profile/GddPasswordIcon";
import PasswordDetails from "../../pages/profile/PasswordDetails";
import { useGddForm } from "../../components/gdd-form/UseGddForm";
import { useConfirm } from "material-ui-confirm";
import GddControls from "../../components/gdd-controls/GddControls";
import useAxios from "../../api/useAxios";

const initialFieldValues = {
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
};

function GddChangePwdMenu(props) {
    const { onClose, onKeyDown } = props;
    const { ENDPOINTS, createApiEndPoint, axiosApiInstance } = useAxios();

    const validate = (fieldValues = values) => {
        // Update error only for the given property.
        let temp = { ...errors };

        if ("currentPassword" in fieldValues)
            temp.currentPassword =
                fieldValues.currentPassword !== ""
                    ? ""
                    : "Current Password is required";

        if ("newPassword" in fieldValues)
            temp.newPassword =
                fieldValues.newPassword !== ""
                    ? ""
                    : "New Password is required";

        if ("confirmNewPassword" in fieldValues)
            temp.confirmNewPassword =
                fieldValues.confirmNewPassword !== ""
                    ? ""
                    : "Confirm Password is required";

        if (["newPassword", "confirmNewPassword"] in fieldValues) {
            if (
                fieldValues.newPassword !== "" &&
                fieldValues.confirmNewPassword !== ""
            ) {
                temp.confirmNewPassword =
                    fieldValues.newPassword === fieldValues.confirmNewPassword
                        ? ""
                        : "Confirm Password does not match the New Password";
            }
        }
        setErrors({ ...temp });

        // Return the following only in case of whole form validation (not single form validation)
        if (fieldValues === values)
            return Object.values(temp).every((x) => x === "");
    };

    const [openPopup, setopenPopup] = useState(false);

    const [disabled, setDisabled] = useState(false);

    const [snackbarState, setSnackbarState] = useState({
        openSnackbar: false,
        snackbarMsg: "",
        snackbarSeverity: "success",
    });
    const { openSnackbar, snackbarMsg, snackbarSeverity } = snackbarState;

    const confirm = useConfirm();

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm,
        editMode,
        setEditMode,
    } = useGddForm(initialFieldValues, validate, true);

    const addOrEdit = (pwdDetails, isEditMode) => {
        updatePassword(pwdDetails);
        //onClose();
    };

    const openClosePopup = (open) => {
        setopenPopup(open);

        // Close the menu when the popup is closed.
        if (!open) {
            onClose();
        }
    };

    const updatePassword = (pwdDetails) => {
        createApiEndPoint(ENDPOINTS.USERS)
            .updateWithoutId(pwdDetails, "update/password")
            .then((res) => {
                // setopenPopup(false);
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: "Successfully updated Password",
                    snackbarSeverity: "success",
                });
                
                setDisabled(true);
                setTimeout(() => {
                    resetForm();
                    openClosePopup(false);
                }, 1500);
            })
            .catch((error) => {
                let msg = "Failed to update Password: ";
                if (error.response && error.response.data) {
                    msg = error.response.data.message;
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    return (
        <MenuItem onClick={() => setopenPopup(true)}>
            <div>
                <ListItemIcon>
                    <GddPasswordIcon fontSize="small" />
                </ListItemIcon>
                <PasswordDetails
                    open={openPopup}
                    setOpen={openClosePopup}
                    onKeyDown={onKeyDown}
                    addOrEdit={addOrEdit}
                    disabled={disabled}
                    {...{
                        values,
                        setValues,
                        errors,
                        setErrors,
                        handleInputChange,
                        resetForm,
                        validate,
                        editMode,
                    }}
                />
                <GddControls.GddSnackbar
                    open={openSnackbar}
                    message={snackbarMsg}
                    onClose={() =>
                        setSnackbarState({
                            ...snackbarState,
                            openSnackbar: false,
                        })
                    }
                    severity={snackbarSeverity}
                />
            </div>
            Change Password
        </MenuItem>
    );
}

export default GddChangePwdMenu;
