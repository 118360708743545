import React, { useContext }  from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LogoutUserIcon from "../../icons/logged-user/LogoutUserIcon";
import { Tooltip } from "@mui/material";
import { AuthContext } from "../../auth/AuthContext";

function LogoutNav({ iconColor, showToolTip }) {
    //const { logout } = useContext(AuthContext);

    return (
        <div>
            <List
                sx={{
                    width: "100%",
                    maxWidth: 360,
                    bgcolor: "background.paper",
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
            >
                <Tooltip title={showToolTip ? "Logout" : ""} arrow={true}>
                    <ListItemButton>
                        <ListItemIcon>
                            <LogoutUserIcon iconColor={iconColor} />
                        </ListItemIcon>
                        <ListItemText primary="Logout" />
                    </ListItemButton>
                </Tooltip>
            </List>
        </div>
    );
}

export default LogoutNav;
