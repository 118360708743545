import React, { useState } from "react";

export function useGddForm(initialValues, validate, validateOnChange = false) {
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [editMode, setEditMode] = useState(false);

    const handleInputChange = (e, dependentVals = null) => {
        // dependentVals (a default param) above is to check the combination of e.target.name and depenedentVals (Mutual dependency).
        // For an example of usage, please see PackageDetails.jsx and Packages.jsx for:
        //  <GddControls.GddSingleSelect
        //              autoCRef = { autoCFlightData };
        //              items = { flightData };
        //              name = "flightDataSelection";
        //
        //  and
        //
        //  <GddControls.GddSingleSelect
        //              autoCRef = { autoCReportData };
        //              items = { reportsData };
        //              name = "reportDataSelection";
        //
        //  See the validate() of Packages.jsx to know how the dpendentVals is used.
        //

        const { name, value } = e.target;

        setValues({
            ...values,
            [name]: value,
        });

        // If validateOnChange is true, try to validate.
        if (validateOnChange) validate({ [name]: value }, dependentVals);
    };

    const resetForm = () => {
        setValues(initialValues);
        setErrors({});
    };

    return {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm,
        editMode,
        setEditMode,
    };
}
