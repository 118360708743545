import React, { useEffect, useState } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
    Autocomplete,
    Checkbox,
    createFilterOptions,
    TextField,
} from "@mui/material";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
function GddMultiSelect({
    items,
    values,
    label,
    name,
    required,
    error,
    placeholder,
    selectAllLabel,
    noOptionsText,
    limitTags,
    onChange,
    onImport,
    includeImportOption,
    autoCRef,
}) {
    const [selectedOptions, setSelectedOptions] = useState(values);
    const allSelected = items.length === selectedOptions.length;

    const handleToggleOption = (selectedOptions) =>
        setSelectedOptions(selectedOptions);

    const handleClearOptions = () => setSelectedOptions([]);

    const getOptionLabel = (option) => {
        return `${option.label}`;
    };

    const handleSelectAll = (isSelected) => {
        if (isSelected) {
            setSelectedOptions(items);
        } else {
            handleClearOptions();
        }
    };

    const handleToggleSelectAll = () => {
        handleSelectAll && handleSelectAll(!allSelected);
    };

    const handleChange = (event, selectedOptions, reason) => {
        if (reason === "selectOption" || reason === "removeOption") {
            if (includeImportOption) {
                if (
                    selectedOptions.find(
                        (option) => option.value === "import-ext"
                    ) &&
                    onImport
                ) {
                    let selectedValues = selectedOptions.filter(
                        (option) => option.value !== "import-ext"
                    );
                    onImport(selectedValues, setSelectedOptions);
                    return;
                }
            }
            if (
                selectedOptions.find((option) => option.value === "select-all")
            ) {
                handleToggleSelectAll();
                let result = [];
                result = items.filter((el) => el.value !== "select-all");
                return onChange(result);
            } else {
                handleToggleOption && handleToggleOption(selectedOptions);
                return onChange(selectedOptions);
            }
        } else if (reason === "clear") {
            handleClearOptions && handleClearOptions();
            return onChange(selectedOptions, reason);
        }
    };

    useEffect(() => {
        setSelectedOptions(values);
    }, []);
    // const optionRenderer = (option, { selected }) => {
    //     const selectAllProps =
    //         option.value === "select-all" // To control the state of 'select-all' checkbox
    //             ? { checked: allSelected }
    //             : {};

    //     console.log("selectAllProps");
    //     console.log(option.value);
    //     return (
    //         <>
    //             <Checkbox
    //                 color="primary"
    //                 icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
    //                 checkedIcon={<CheckBoxIcon fontSize="small" />}
    //                 style={{ marginRight: 8 }}
    //                 checked={selected}
    //                 {...selectAllProps}
    //             />
    //             {getOptionLabel(option)}
    //         </>
    //     );
    // };
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    const optionRenderer = (props, option, { selected }) => {
        const selectAllProps =
            option.value === "select-all" // To control the state of 'select-all' checkbox
                ? { checked: allSelected }
                : {};
        return option.value !== "import-ext" ? (
            <li {...props}>
                <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                    {...selectAllProps}
                />
                {option.label}
            </li>
        ) : (
            <li style={{ marginLeft: "10px" }} {...props}>
                {option.label}
            </li>
        );
    };
    // const inputRenderer = (params) => (
    //     <TextField {...params} label={label} placeholder={placeholder} />
    // );

    const inputRenderer = (params) => {
        const { InputProps, ...restParams } = params;
        const { startAdornment, ...restInputProps } = InputProps;
        return (
            <TextField
                label={label}
                required={required}
                name={name}
                {...(error && { error: true, helperText: error })}
                placeholder={placeholder}
                {...restParams}
                InputProps={{
                    ...restInputProps,
                    startAdornment: (
                        <div
                            style={{
                                maxHeight: 100,
                                overflow: "auto",
                            }}
                        >
                            {startAdornment}
                        </div>
                    ),
                }}
            />
        );
    };
    const getOptionSelected = (option, anotherOption) =>
        option.value === anotherOption.value;

    const getLimitTagsText = (more) => {
        return `+${more} of ${items.length}`;
    };

    const filter = createFilterOptions();

    return (
        <Autocomplete
            ref={autoCRef}
            multiple
            size="small"
            limitTags={limitTags}
            options={items}
            value={selectedOptions}
            disableCloseOnSelect
            getOptionLabel={getOptionLabel}
            getOptionSelected={getOptionSelected}
            noOptionsText={noOptionsText}
            getLimitTagsText={getLimitTagsText}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                let all =
                    filtered.length > 0
                        ? [
                              { label: selectAllLabel, value: "select-all" },
                              ...filtered,
                          ]
                        : [];
                if (includeImportOption) {
                    all = [{ label: "Import...", value: "import-ext" }, ...all];
                }
                // return [
                //     { label: selectAllLabel, value: "select-all" },
                //     ...filtered,
                // ];
                // all = [{ label: "Import...", value: "import-ext" }, ...all];
                return all;
            }}
            onChange={handleChange}
            renderOption={optionRenderer}
            renderInput={inputRenderer}
            isOptionEqualToValue={(option, value) => option.id === value.id}
        />
    );
}

GddMultiSelect.defaultProps = {
    limitTags: 3,
    items: [],
    selectedOptions: [],
    includeImportOption: false,
    getOptionLabel: (value) => value,
};

export default GddMultiSelect;
