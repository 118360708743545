import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import GddControls from "../../../components/gdd-controls/GddControls";
import GddDialog from "../../../components/gdd-dialog/GddDialog";
import GddDialogTitle from "../../../components/gdd-dialog/GddDialogTitle";
import ManufacturersIcon from "../../../icons/admin/ManufacturersIcon";

function ManufacturerDetails(props) {
    const {
        open,
        setOpen,
        addOrEdit,
        values,
        setValues,
        setErrors,
        errors,
        handleInputChange,
        resetForm,
        validate,
        editMode,
    } = props;

    const iconType = <ManufacturersIcon iconSize="medium" />;
    const dlgTitle = (
        <GddDialogTitle
            title={editMode ? "Edit Airframer" : "Create New Airframer"}
            titleIcon={iconType}
        />
    );

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            addOrEdit(values, editMode);
        }
    };

    return (
        <div>
            <GddDialog
                title={dlgTitle}
                open={open}
                setOpen={setOpen}
                handleSubmit={handleSubmit}
                resetForm={resetForm}
            >
                <Grid
                    container
                    rowSpacing={2}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                    <Grid item xs={6}>
                        <GddControls.GddTextField
                            fullWidth={true}
                            name="name"
                            variant="outlined"
                            label="Name"
                            required={true}
                            value={values.name}
                            onChange={handleInputChange}
                            error={errors.name}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <GddControls.GddTextField
                            fullWidth={true}
                            name="description"
                            label="Description"
                            required={false}
                            value={values.description}
                            onChange={handleInputChange}
                            error={errors.description}
                        />
                    </Grid>
                </Grid>
            </GddDialog>
        </div>
    );
}

export default ManufacturerDetails;
