import React , { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import GddControls from "../../../components/gdd-controls/GddControls";
import GddDialog from "../../../components/gdd-dialog/GddDialog";
import GddDialogTitle from "../../../components/gdd-dialog/GddDialogTitle";
import SecurityIcon from "@mui/icons-material/VpnKey";
import useAxios from "../../../api/useAxios";


function OAuthDetails(props){
    const {
        open,
        setOpen,
        addOrEdit,
        values,
        setValues,
        setErrors,
        errors,
        handleInputChange,
        resetForm,
        validate,
        editMode,       
    } = props;

    const iconType = <SecurityIcon iconSize="medium" />;
    const dlgTitle = (
        <GddDialogTitle
            title={editMode ? "Edit OAuth" : "Create New OAuth"}
            titleIcon={iconType}
        />
    );

    const { ENDPOINTS, createApiEndPoint, axiosApiInstance } = useAxios();

    const [airlines, setAirlines] = useState([]);

    useEffect(() => {
        createApiEndPoint(ENDPOINTS.AIRLINE)
        .fetchAll("getPage?pagedSearch.page=1&pagedSearch.pageSize=1000")
        .then((res) => {
            const airlinesToDisplay = res.data.items;
            if (airlinesToDisplay) {
                let airlineOptions = airlinesToDisplay.sort((a, b) => (a.name > b.name) ? 1 : -1).map((item) => ({
                    id: item.id,
                    label: item.name,
                    value: item.mane,
                    description: item.name,
                }));
                setAirlines(airlineOptions);
            } else {
                setAirlines([]);
            }
        })
        .catch((err) => console.log(err));
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();       
           
        if (validate()) {
            
            let OAuthToSubmit = {
                ...values,
                airlines: values.airlines.map((item) => ({
                    id: item.id,
                    name: item.label,
                })),
            };        
            addOrEdit(OAuthToSubmit, editMode);
        }
    };


    return (
        <div>
            <GddDialog
                title={dlgTitle}
                open={open}
                setOpen={setOpen}
                handleSubmit={handleSubmit}
                resetForm={resetForm}
            >
                <Grid
                    container
                    rowSpacing={2}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                    { editMode && <Grid item xs={12}>
                       <GddControls.GddTextField
                           fullWidth={true}
                           name="clientId"
                           variant="outlined"
                           label="ClientId"
                           required={true}                           
                           value={values.clientId}
                           onChange={handleInputChange}
                           error={errors.clientId}
                           disabled={true}
                       />
                   </Grid> }
                    
                    { editMode && <Grid item xs={12}>
                        <GddControls.GddTextField
                            fullWidth={true}
                            name="clientSecret"
                            label="Client Secret"
                            required={true}
                            value={values.clientSecret}
                            onChange={handleInputChange}
                            error={errors.clientSecret}
                            disabled={true}
                        />
                    </Grid> }
                    <Grid item xs={12}>
                        <GddControls.GddMultiSelect
                            items={airlines}                           
                            label="Select airlines"
                            name="airlines"
                            placeholder="Airlines"
                            selectAllLabel="Select all"
                            onChange={(v) => {
                                let evt = {
                                    target: {
                                        name: "airlines",
                                        value: [...v],
                                    },
                                };

                                handleInputChange(evt);
                            }}
                            values={values.airlines}
                            noOptionsText="No Airlines to list"
                            includeImportOption={false}   
                            error={errors.airlines}                         
                        />
                    </Grid>  
                </Grid>
            </GddDialog>
        </div>
    );


}

export default OAuthDetails;