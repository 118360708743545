import { Alert, AlertTitle, Dialog } from "@mui/material";
import React, { useState } from "react";

function GddAlert({ open, handleClose }) {
    return (
        <Dialog open={open} onClose={handleClose}>
            <Alert severity="info">
                <AlertTitle>Attention</AlertTitle>
                Any changes to this B787 Report Data Frame must be reflected in
                any reports associated with it.
            </Alert>
        </Dialog>
    );
}

export default GddAlert;
