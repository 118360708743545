import React from "react";
import Divider from "@mui/material/Divider";
import AircraftInfoNav from "./AircraftInfoNav";
import ParameterMappingNav from "./ParameterMappingNav";
import StatsNav from "./StatsNav";
import LoggedInUserNav from "./LoggedInUserNav";
import LogoutNav from "./LogoutNav";
import AdminNav from "./AdminNav";
import HomeNav from "./HomeNav";

function Sidebar({ iconColor, routeTo, showToolTip }) {
    return (
        <div>
            {/* <HomeNav
                iconColor={iconColor}
                routeTo={routeTo}
                showToolTip={showToolTip}
            />
            <Divider /> */}

            <AdminNav
                iconColor={iconColor}
                routeTo={routeTo}
                showToolTip={showToolTip}
            />
            <Divider />
            <AircraftInfoNav
                iconColor={iconColor}
                routeTo={routeTo}
                showToolTip={showToolTip}
            />
            <Divider />
            <ParameterMappingNav
                iconColor={iconColor}
                routeTo={routeTo}
                showToolTip={showToolTip}
            />
            <Divider />
            <StatsNav
                iconColor={iconColor}
                routeTo={routeTo}
                showToolTip={showToolTip}
            />
            <Divider />
            {/* <SecurityNav
                iconColor={iconColor}
                routeTo={routeTo}
                showToolTip={showToolTip}
            /> */}
            {/* <LoggedInUserNav
                iconColor={iconColor}
                routeTo={routeTo}
                showToolTip={showToolTip}
            />
            <Divider />
            <LogoutNav
                iconColor={iconColor}
                routeTo={routeTo}
                showToolTip={showToolTip}
            /> */}
        </div>
    );
}

export default Sidebar;
