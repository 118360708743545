import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";

import GddDialog from "../../components/gdd-dialog/GddDialog";
import GddDialogTitle from "../../components/gdd-dialog/GddDialogTitle";
import GddEmailIcon from "../../icons/profile/GddEmailIcon";
import GddControls from "../../components/gdd-controls/GddControls";

function EmailDetails(props) {
    const {
        open,
        setOpen,
        addOrEdit,
        values,
        setValues,
        setErrors,
        errors,
        handleInputChange,
        resetForm,
        validate,
        editMode,
        onKeyDown,
        disabled
    } = props;

    const iconType = <GddEmailIcon iconSize="medium" />;
    const dlgTitle = (
        <GddDialogTitle title="Change Email" titleIcon={iconType} />
    );

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            addOrEdit(values, editMode);
        }
    };

    return (
        <div>
            <GddDialog
                title={dlgTitle}
                open={open}
                setOpen={setOpen}
                onKeyDown={onKeyDown}
                handleSubmit={handleSubmit}
                resetForm={resetForm}
                disabled={disabled}
            >
                <Grid
                    container
                    rowSpacing={2}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                    <Grid item xs={12}>
                        <GddControls.GddTextField
                            fullWidth={true}
                            type="password"
                            name="currentPassword"
                            variant="outlined"
                            label="Password"
                            required={true}
                            value={values.currentPassword}
                            onChange={handleInputChange}
                            error={errors.currentPassword}
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <GddControls.GddTextField
                            fullWidth={true}
                            name="newEmail"
                            variant="outlined"
                            label="New Email"
                            required={true}
                            value={values.newEmail}
                            onChange={handleInputChange}
                            error={errors.newEmail}
                            disabled={disabled}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <GddControls.GddTextField
                            fullWidth={true}
                            name="confirmNewEmail"
                            variant="outlined"
                            label="Confirm Email"
                            required={true}
                            value={values.confirmNewEmail}
                            onChange={handleInputChange}
                            error={errors.confirmNewEmail}
                            disabled={disabled}
                        />
                    </Grid>
                </Grid>
            </GddDialog>
        </div>
    );
}

export default EmailDetails;
