import { ListItemIcon, MenuItem } from "@mui/material";
import React, { useState } from "react";
import GddEmailIcon from "../../icons/profile/GddEmailIcon";
import EmailDetails from "../../pages/profile/EmailDetails";
import { useGddForm } from "../../components/gdd-form/UseGddForm";
import { useConfirm } from "material-ui-confirm";
import GddControls from "../../components/gdd-controls/GddControls";
import useAxios from "../../api/useAxios";

const initialFieldValues = {
    currentPassword: "",
    newEmail: "",
    confirmNewEmail: "",
};

function GddChangeEmailMenu(props) {
    const { onClose, onKeyDown } = props;

    const { ENDPOINTS, createApiEndPoint, axiosApiInstance } = useAxios();

    const validate = (fieldValues = values) => {
        // Update error only for the given property.
        let temp = { ...errors };

        if ("currentPassword" in fieldValues)
            temp.currentPassword =
                fieldValues.currentPassword !== ""
                    ? ""
                    : "Password is required";

        if ("newEmail" in fieldValues)
            temp.newEmail =
                fieldValues.newEmail !== "" ? "" : "New Email is required";

        if ("confirmNewEmail" in fieldValues)
            temp.confirmNewEmail =
                fieldValues.confirmNewEmail !== ""
                    ? ""
                    : "Confirm New Email is required";

        if (["newEmail", "confirmNewEmail"] in fieldValues) {
            if (
                fieldValues.newEmail !== "" &&
                fieldValues.confirmNewEmail !== ""
            ) {
                temp.confirmNewEmail =
                    fieldValues.newEmail === fieldValues.confirmNewEmail
                        ? ""
                        : "Confirm Email does not match the New Email";
            }
        }
        setErrors({ ...temp });

        // Return the following only in case of whole form validation (not single form validation)
        if (fieldValues === values)
            return Object.values(temp).every((x) => x === "");
    };

    const [openPopup, setopenPopup] = useState(false);

    const [disabled, setDisabled] = useState(false);

    const [snackbarState, setSnackbarState] = useState({
        openSnackbar: false,
        snackbarMsg: "",
        snackbarSeverity: "success",
    });
    const { openSnackbar, snackbarMsg, snackbarSeverity } = snackbarState;

    const confirm = useConfirm();

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm,
        editMode,
        setEditMode,
    } = useGddForm(initialFieldValues, validate, true);

    const addOrEdit = (emailDetails, isEditMode) => {
        updateEmail(emailDetails);
        //onClose();
    };

    const openClosePopup = (open) => {
        setopenPopup(open);

        // Close the menu when the popup is closed.
        if (!open) {
            onClose();
        }
    };

    const updateEmail = (emailDetails) => {
        createApiEndPoint(ENDPOINTS.USERS)
            .updateWithoutId(emailDetails, "update/email")
            .then((res) => {
                // setopenPopup(false);
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: "Successfully updated Email",
                    snackbarSeverity: "success",
                });
                
                setDisabled(true);
                setTimeout(() => {
                    resetForm();
                    openClosePopup(false);
                }, 1500);
            })
            .catch((error) => {
                let msg = "Failed to update Email: ";
                if (error.response && error.response.data) {
                    msg = error.response.data.message;
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }

                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    return (
        <MenuItem onClick={() => setopenPopup(true)}>
            <div>
                <ListItemIcon>
                    <GddEmailIcon fontSize="small" />
                </ListItemIcon>
                <EmailDetails
                    open={openPopup}
                    setOpen={openClosePopup}
                    onKeyDown={onKeyDown}
                    addOrEdit={addOrEdit}
                    disabled={disabled}
                    {...{
                        values,
                        setValues,
                        errors,
                        setErrors,
                        handleInputChange,
                        resetForm,
                        validate,
                        editMode,
                    }}
                />
                <GddControls.GddSnackbar
                    open={openSnackbar}
                    message={snackbarMsg}
                    onClose={() =>
                        setSnackbarState({
                            ...snackbarState,
                            openSnackbar: false,
                        })
                    }
                    severity={snackbarSeverity}
                />
            </div>
            Change Email
        </MenuItem>
    );
}

export default GddChangeEmailMenu;
