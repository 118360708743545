import { Card, CardHeader, Checkbox } from "@mui/material";
import React, { useState } from "react";

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
    return [...a, ...not(b, a)];
}

function GddCustomList(props) {
    const { title, initialData, ...other } = { props };
    const [data, setData] = useState(initialData || []);
    const [checked, setChecked] = React.useState([]);

    const numberOfChecked = (items) => intersection(checked, items).length;

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };

    return (
        <Card elevation={5}>
            <CardHeader
                sx={{ px: 0, py: 0 }}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(data)}
                        checked={
                            numberOfChecked(data) === data.length &&
                            data.length !== 0
                        }
                        indeterminate={
                            numberOfChecked(data) !== data.length &&
                            numberOfChecked(data) !== 0
                        }
                        disabled={data.length === 0}
                        inputProps={{
                            "aria-label": "all items selected",
                        }}
                    />
                }
                title={title}
                subheader={`${numberOfChecked(data)}/${data.length} selected`}
            />
        </Card>
    );
}

export default GddCustomList;
