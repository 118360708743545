import React, { useEffect, useState, useRef, useContext } from "react";
import GddControls from "../../../components/gdd-controls/GddControls";
import GddTable from "../../../components/gdd-table/GddTable";
import TableTitle from "../../../components/gdd-table/TableTitle";
import { useGddForm } from "../../../components/gdd-form/UseGddForm";
import SecurityIcon from "@mui/icons-material/VpnKey";
import { useConfirm } from "material-ui-confirm";
import useAxios from "../../../api/useAxios";
import OAuthDetails from "./OAuthDetails";

const initialFieldValues = {
    id: 0,
    clientId: "",
    clientSecret: "",    
    airlines: [],
};

function OAuthPage() {
    const { ENDPOINTS, createApiEndPoint, axiosApiInstance } = useAxios();

    const validate = (fieldValues = values) => {
        let temp = { ...errors };

        if ("airlines" in fieldValues)
        temp.airlines =
            fieldValues.airlines.length !== 0 ? "" : "Airline is required";

        setErrors({ ...temp });

        if (fieldValues === values)
            return Object.values(temp).every((x) => x === "");
    };

    const confirm = useConfirm();

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm,
        editMode,
        setEditMode,
    } = useGddForm(initialFieldValues, validate, true);

    const iconType = <SecurityIcon iconSize="medium" />;
    const tableTitle = <TableTitle title="OAuth Info" titleIcon={iconType} />;

    const [openPopup, setopenPopup] = useState(false);

    const [snackbarState, setSnackbarState] = useState({
        openSnackbar: false,
        snackbarMsg: "",
        snackbarSeverity: "success",
    });

    const { openSnackbar, snackbarMsg, snackbarSeverity } = snackbarState;
    const [fetchCount, setFetchCount] = useState(0);    

    const tableRef = useRef();

    const [defaultFilters, setDefaultFilters] = useState({});

    const getDefaultFilter = (columnName) => {
        if (defaultFilters[columnName]) return defaultFilters[columnName];
        else return null;
    };

    const cols = [
        {
            title: "Id",
            field: "id",
            filterPlaceholder: "Id",
            filtering: false,
            hidden: true,
        },
        {
            title: "Client Id",
            field: "clientId",
            filterPlaceholder: "Filter by Client Id",
            filtering: true,
        },
        {
            title: "Client Secret",
            field: "clientSecret",
            filterPlaceholder: "Filter by Client Secret",
            filtering: true,
        },
        {
            title: "Airline",
            field: "airline",
            filterPlaceholder: "Filter by Airline",
            filtering: true,
            render: (rowData) => (
                <p style={{ padding: 0, margin: 0 }}>
                    {rowData.airlines.join(", ")}
                </p>
            ),
        },
    ];

    cols.forEach((item) => (item.defaultFilter = getDefaultFilter(item.field)));

    useEffect(() => {
        tableRef.current.onQueryChange();
    }, [fetchCount]);

    const addOrEdit = (oAuth, isEditMode) => {
        if (!isEditMode) {
            creatOAuth(oAuth);
        } else {
            updateOAuth(oAuth);
        }
    };

    const creatOAuth = (oAuth) => {
        createApiEndPoint(ENDPOINTS.OPENAUTH)
            .create(oAuth)
            .then((res) => {
                resetForm();
                setopenPopup(false);
                //setData([...data, res.data]);
                setFetchCount(fetchCount + 1);
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: "Successfully created OAuth",
                    snackbarSeverity: "success",
                });
            })
            .catch((error) => {
                let msg = "Failed to create OAuth: ";
                if (error.response && error.response.data) {
                    if (error.response.data.exceptionMessage) {
                        msg = error.response.data.exceptionMessage;
                    } else {
                        msg = error.response.data.message;
                    }
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const updateOAuth = (oAuth) => {
        createApiEndPoint(ENDPOINTS.OPENAUTH)
            .updateWithoutId(oAuth, "update")
            .then((res) => {
                resetForm();
                setopenPopup(false);
                setFetchCount(fetchCount + 1);
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: "Successfully updated OAuth",
                    snackbarSeverity: "success",
                });
            })
            .catch((error) => {
                let msg = "Failed to update OAuth: ";
                if (error.response && error.response.data) {
                    msg = error.response.data.message;
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }

                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const deleteOAuth = (oAuth) => {
        createApiEndPoint(ENDPOINTS.OPENAUTH)
            .delete(oAuth.id, "delete")
            .then((res) => {
                setFetchCount(fetchCount + 1);
                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: "Successfully deleted OAuth",
                    snackbarSeverity: "success",
                });
            })
            .catch((error) => {
                let msg = "Failed to delete OAuth: ";
                if (error.response && error.response.data) {
                    msg = error.response.data.message;
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }

                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const addClicked = () => {
        resetForm();
        setEditMode(false);
        setopenPopup(true);        
    };

    const editClicked = (selectedOAuth) => {
        createApiEndPoint(ENDPOINTS.OPENAUTH)
            .fetchById(selectedOAuth.id, "edit")
            .then((res) => {
                const oAuth = res.data;                     
                setValues({
                    ...values,
                    id: oAuth.id,
                    clientId: oAuth.clientId,
                    clientSecret: oAuth.clientSecret,
                    airlines: oAuth.airlines.map((item) => ({
                                id: item.airline.id,
                                label: item.airline.name,
                                value: item.airline.name,
                                description: item.airline.name,
                            })),
                });
                setEditMode(true);
                setopenPopup(true);
            })
            .catch((error) => {
                let msg = "Failed to fetch OAuth: ";
                if (error.response && error.response.data) {
                    msg = error.response.data.message;
                } else if (error.request) {
                    msg = msg + error.request;
                } else {
                    msg = msg + "Error: " + error.message;
                }

                setSnackbarState({
                    ...snackbarState,
                    openSnackbar: true,
                    snackbarMsg: msg,
                    snackbarSeverity: "error",
                });
            });
    };

    const deleteClicked = (oAuth) => {
        confirm({
            description: `Are you sure you want to delete the OAuth?'`,
        })
            .then(() => deleteOAuth(oAuth))
            .catch(() => {});
    };

    return (
        <div>
            <OAuthDetails
                open={openPopup}
                setOpen={setopenPopup}
                addOrEdit={addOrEdit}
                {...{
                    values,
                    setValues,
                    errors,
                    setErrors,
                    handleInputChange,
                    resetForm,
                    validate,
                    editMode,
                }}               
            />

            <GddTable
                tableRef={tableRef}
                title={tableTitle}
                cols={cols}
                fetchEndPoint={ENDPOINTS.OPENAUTH}
                saveFilters={setDefaultFilters}
                baseUrlExt="getPage"
                pagedSearchString="pagedSearch"
                onAdd={addClicked}
                onEdit={editClicked}
                onDelete={deleteClicked}
            ></GddTable>

            <GddControls.GddSnackbar
                open={openSnackbar}
                message={snackbarMsg}
                onClose={() =>
                    setSnackbarState({ ...snackbarState, openSnackbar: false })
                }
                severity={snackbarSeverity}
            />
        </div>
    );
}

export default OAuthPage;
