import { Grid } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import GddControls from "../../components/gdd-controls/GddControls";
import GddDialog from "../../components/gdd-dialog/GddDialog";
import GddDialogTitle from "../../components/gdd-dialog/GddDialogTitle";

import ReportIcon from "../../icons/param-mapping/ReportIcon";
import useAxios from "../../api/useAxios";

function ReportDetails(props) {
    const {
        open,
        setOpen,
        addOrEdit,
        values,
        setValues,
        setErrors,
        errors,
        handleInputChange,
        resetForm,
        validate,
        editMode,
        isAdmin,
    } = props;

    const { ENDPOINTS, createApiEndPoint, axiosApiInstance } = useAxios();

    const iconType = <ReportIcon iconSize="medium" />;

    const dlgTitle = (
        <GddDialogTitle
            title={
                editMode
                    ? "Edit Report Configuration"
                    : "Create New Report Configuration"
            }
            titleIcon={iconType}
        />
    );

    const [dataFrames, setDataFrames] = useState([]);
    const [airlines, setAirlines] = useState([]);
    const [reports, setReports] = useState([]);

    const autoCReports = useRef();

    const clearRedactedReportsSelection = () => {
        const ele = autoCReports.current;

        if (ele) {
            const clearBtn = ele.getElementsByClassName(
                "MuiAutocomplete-clearIndicator"
            )[0];
            if (clearBtn) {
                clearBtn.click();
            }
        }
    };

    useEffect(() => {
        if (isAdmin) {
            createApiEndPoint(ENDPOINTS.AIRLINE)
                .fetchAll("getKeyValuePairs")
                .then((res) => {
                    const airlineData = res.data;
                    let airlineOptions = airlineData
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((item) => ({
                            id: item.id,
                            title: item.name,
                        }));
                    setAirlines(airlineOptions);
                })
                .catch((err) => console.log(err));
        }
    }, []);

    useEffect(() => {
        if (values.airlineId > 0) {
            createApiEndPoint(ENDPOINTS.DATAFRAME)
                .fetchAll(
                    `getSelectListDtoByAirlineAndDataFrameType?dataIn=${values.airlineId}&dataIn=4&dataIn=5`
                )
                .then((res) => {
                    const frameData = res.data;
                    if (frameData) {
                        let frameDatalList = frameData
                            .sort((a, b) => (a.name > b.name ? 1 : -1))
                            .map((item) => ({
                                id: item.id,
                                title: item.name,
                            }));
                        setDataFrames(frameDatalList);
                    } else {
                        setDataFrames([]);
                    }
                })
                .catch((err) => console.log(err));
        } else {
            setDataFrames([]);
        }
    }, [values.airlineId]);

    useEffect(() => {
        if (values.airlineId > 0) {
            createApiEndPoint(ENDPOINTS.DATAFRAME)
                .fetchAll(`getReports?dataFrameId=${values.dataFrameId}`)
                .then((res) => {
                    const reportsData = res.data;
                    
                    if (reportsData) {
                        let reportsDataList = reportsData
                            .sort((a, b) => (a.name > b.name ? 1 : -1))
                            .map((item) => ({
                                id: item.id === 0 ? item.name : item.id,
                                label: item.name,
                                value: item.pattern,
                                description: item.name,
                            }));

                        setReports(reportsDataList);
                    } else {
                        setReports([]);
                    }
                })
                .catch((err) => console.log(err));
        } else {
            setReports([]);
        }
    }, [values.dataFrameId]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            addOrEdit(values, editMode);
        }
    };

    return (
        <GddDialog
            title={dlgTitle}
            open={open}
            setOpen={setOpen}
            handleSubmit={handleSubmit}
            resetForm={resetForm}
        >
            <Grid
                container
                rowSpacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
                <Grid item xs={12}>
                    <GddControls.GddTextField
                        fullWidth={true}
                        name="name"
                        variant="outlined"
                        label="Name"
                        required={true}
                        value={values.name}
                        onChange={handleInputChange}
                        error={errors.name}
                    />
                </Grid>
                <Grid item xs={12}>
                    <GddControls.GddTextField
                        fullWidth={true}
                        name="description"
                        label="Description"
                        required={false}
                        value={values.description}
                        onChange={handleInputChange}
                        error={errors.description}
                    />
                </Grid>

                {isAdmin && (
                    <Grid item xs={12}>
                        <GddControls.GddSelect
                            fullWidth={true}
                            name="airlineId"
                            label="Airline"
                            required={true}
                            options={airlines}
                            value={values.airlineId}
                            onChange={(e) => {
                                clearRedactedReportsSelection();
                                values.dataFrameId = "";
                                setTimeout(() => {
                                    handleInputChange(e);
                                }, 100);
                            }}
                            error={errors.airlineId}
                        />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <GddControls.GddSelect
                        fullWidth={true}
                        name="dataFrameId"
                        label="Data Frame"
                        required={true}
                        options={dataFrames}
                        value={values.dataFrameId}
                        onChange={(e) => {
                            clearRedactedReportsSelection();
                            handleInputChange(e);
                        }}
                        error={errors.dataFrameId}
                    />
                </Grid>

                <Grid item xs={12}>
                    <GddControls.GddMultiSelect
                        autoCRef={autoCReports}
                        items={reports}
                        // getOptionDisabled={getOptionDisabled}
                        required
                        label="Select Reports"
                        placeholder="Reports"
                        selectAllLabel="Select all"
                        onChange={(vals) => {
                            let evt = {
                                target: {
                                    name: "redactedReports",
                                    value: [...vals],
                                },
                            };
                            handleInputChange(evt);
                        }}
                        values={values.redactedReports}
                        error={errors.redactedReports}
                        noOptionsText="No reports to list"
                        includeImportOption={false}
                    />
                </Grid>
            </Grid>
        </GddDialog>
    );
}

export default ReportDetails;
