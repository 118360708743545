import React, { useState } from "react";
import { styled } from "@mui/material/styles";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import PropTypes from "prop-types";
import GddButton from "../gdd-controls/GddButton";
import GddLoadingButton from "../gdd-controls/GddLoadingButton";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2, bgcolor: "#f5f5f5" }} {...other}>
            <div>
                {children}
                {onClose ? (
                    <IconButton
                        size="medium"
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </div>
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

function GddDialog(props) {
    //const [open, setOpen] = React.useState(false);
    const {
        title,
        children,
        open,
        setOpen,
        onKeyDown,
        handleSubmit,
        resetForm,
        onClosing,
        disabled = false,
        ...other
    } = props;

    const [isSaving, setIsSaving] = useState(false);

    const handleClose = () => {
        onClosing && onClosing();
        setOpen(false);
    };

    const saveClicked = (e) => {
        setIsSaving(true);
        handleSubmit(e);
        setIsSaving(false);
    };

    return (
        <BootstrapDialog
            onClose={handleClose}
            onKeyDown={onKeyDown}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            disableEscapeKeyDown
            fullWidth={true}
            open={open}
            {...other}
        >
            <BootstrapDialogTitle
                style={{ cursor: "move" }}
                id="draggable-dialog-title"
                onClose={handleClose}
            >
                {title}
            </BootstrapDialogTitle>
            <DialogContent dividers>
                <form autoComplete="off">{children}</form>
            </DialogContent>
            <DialogActions disableSpacing>
                <GddLoadingButton
                    text="Save"
                    size="medium"
                    startIcon={<SaveIcon />}
                    onClick={saveClicked}
                    type="submit"
                    disabled={disabled}
                    loading={isSaving}
                    loadingPosition="start"
                    variant="contained"
                ></GddLoadingButton>

                <GddButton
                    text="Reset"
                    size="medium"
                    startIcon={<RestartAltIcon />}
                    color="secondary"
                    autoFocus
                    onClick={resetForm}
                    disabled={disabled}
                ></GddButton>
            </DialogActions>
        </BootstrapDialog>
    );
}

export default GddDialog;
