import React from "react";
import TextField from "@mui/material/TextField";

function GddTextField(props) {
    const {
        name,
        label,
        value,
        required,
        variant = "outlined",
        disabled = false,
        error = null,
        inputRef = null,
        onChange,
        ...other
    } = props;

    return (
        <TextField
            inputRef={inputRef}
            name={name}
            size="small"
            variant={variant}
            disabled={disabled}
            label={label}
            required={required}
            value={value}
            onChange={onChange}
            {...(error && { error: true, helperText: error })}
            {...other}
        ></TextField>
    );
}

export default GddTextField;
