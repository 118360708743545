import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function GddSnackbar(props) {
    const { message, severity, open, onClose } = props;

    return (
        <Snackbar
            color="success"
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            autoHideDuration={5000}
            open={open}
            onClose={onClose}
            key={"top-right"}
        >
            <Alert onClose={onClose} severity={severity} sx={{ width: "100%" }}>
                <div id="alert-msg">{message}</div>
            </Alert>
        </Snackbar>
    );
}

export default GddSnackbar;
